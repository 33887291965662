import { Component, Input, OnInit } from '@angular/core';
import {
  MixedLoyaltyItemType,
  MixedLoyaltyQuery,
  MixedLoyaltyWalletItem,
  ProcessProviderId,
  ScorecardService,
  RewardsWalletService,
  RewardsWalletItem,
  RewardsWalletQuery,
} from '@fgb/core';
import { Observable } from 'rxjs';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';

@Component({
  selector: 'fgb-wallet-page',
  templateUrl: './wallet-page.component.html',
  styleUrls: ['./wallet-page.component.scss'],
})
export class WalletPageComponent implements OnInit {
  @Input() marketplaceFilterTypes: ProcessProviderId[] = [0, 1, 2, 3, 4, 5];
  @Input() itemTypes: MixedLoyaltyItemType[] = [MixedLoyaltyItemType.All];
  unclaimedWalletItems$: Observable<MixedLoyaltyWalletItem[]>;
  claimedWalletItems$: Observable<MixedLoyaltyWalletItem[]>;
  isList: boolean = true;
  walletItemsUnclaimed$: Observable<RewardsWalletItem[]>;
  screenType= ScreenType;
  campaignOffersList = [];
  constructor(
    private mixedLoyaltyQuery: MixedLoyaltyQuery,
    private scorecardService: ScorecardService,
    private rewardWalletItemsService: RewardsWalletService,
    private rewardWalletQuery: RewardsWalletQuery
  ) {}

  async ngOnInit() {
    this.rewardWalletItemsService.fetchWalletItems().toPromise();
    this.walletItemsUnclaimed$ = this.rewardWalletQuery.selectRewardWalletItemsByStatus(false);

    await this.mixedLoyaltyQuery.walletFetch();

    /** select claimed marketplace and auction items */
    this.unclaimedWalletItems$ = this.mixedLoyaltyQuery.selectMixedLoyaltyWalletItemsNoFetch(
      false,
      this.marketplaceFilterTypes,
      this.itemTypes
    );
    /** select unclaimed marketplace and auction items */
    this.claimedWalletItems$ = this.mixedLoyaltyQuery.selectMixedLoyaltyWalletItemsNoFetch(
      true,
      this.marketplaceFilterTypes,
      this.itemTypes
    );

    this.scorecardService.fetchScorecards().toPromise();
  }
  onCampaignOfferListChange(e: any){
    this.campaignOffersList = e?.CampaignsAndEntitlements || [];
  }
}
