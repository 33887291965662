<form [formGroup]="addForm" class="row mt-3" (ngSubmit)="submit()">
  <div class="row w-100">
    <div class="partner-form-title d-flex align-items-center justify-content-center w-100">
      <span class="icon material-icons mr-2"> person_add_alt_1 </span>
      <h5 class="text-center mb-0">{{ 'partner.add.form.title' | translate }}</h5>
    </div>
  </div>
  <div class="px-2 row w-100">
    <hr class="partner-divider w-100" />

    <div class="col-12 mt-3">
      <label>{{ 'partner.add.form.fullname.label' | translate }}</label>
      <input formControlName="fullName" placeholder="Enter Name" class="form-control bg-muted" />
    </div>
    <div class="col-12 mt-3">
      <label class="mt-4 mt-lg-0">{{ 'partner.add.form.email.label' | translate }}</label>
      <input formControlName="email" placeholder="Enter Email address" type="email" class="form-control bg-muted" />
    </div>
    <div class="mt-2 px-2 error-message">
      <fgbcl-errors></fgbcl-errors>
    </div>
  </div>

  <div class="button-container d-flex justify-content-between form-buttons mb-3 w-100">
    <button (click)="close(); $event.preventDefault()" class="btn btn-danger font-weight-bold ml-3">
      {{ 'partner.add.form.cancel.button' | translate }}
    </button>
    <button type="submit" [disabled]="addForm.invalid" class="btn btn-success font-weight-bold mr-3">
      {{ 'partner.add.form.submit.button' | translate }}
    </button>
  </div>
</form>
