<div class="container">
  <div class="d-flex mt-3 pt-3">
    <div class="text-danger cursor-pointer back-button">
      <a [routerLink]="'/rewards/wallet'" class="text-danger flex-grow-0 flex-shrink-0 d-flex h-100 font-weight-bold">
        <span class="material-icons"> chevron_left </span>
        <span class="font-size-14 font-weight-bold backbtn">{{ 'campaign.back.to.wallet' | translate }}</span></a
      >
    </div>

    <!-- Image -->
    <div class="loyalty-details-image shadow border-0 rounded overflow-hidden p-0">
      <img
        [ngClass]="{
          'image-opacity': currentCampaign.campaignRedemptions_Quantity == 0
        }"
        alt=""
        [src]="currentCampaign.image_URL"
        class="logo"
      />
    </div>
    <div class="back-button icon-placement">
      <ng-container *ngIf="currentCampaign.campaignRedemptions_Quantity == 0">
        <span class="back-button icon material-icons text-grey text-right font-weight-bold ml-3"> lock </span>
      </ng-container>
      <ng-container *ngIf="currentCampaign.campaignRedemptions_Quantity > 0">
        <span class="back-button icon material-icons text-success text-right font-weight-bold ml-3"> check_circle </span>
      </ng-container>
    </div>
  </div>
  <!-- Name -->
  <div class="text-center font-size-14 font-weight-bold mt-3">{{ currentCampaign.CampaignName }}</div>
  <!-- Expiry -->
  <div class="font-size-12 text-danger text-center pt-lg-2 pt-0">
    {{ 'offer.expiry' | translate }} {{ currentCampaign.Campaign_ExpiryDate | date: 'MMM D, yyyy' }}
  </div>
  <!-- Description -->
  <hr class="border-top border-disabled my-3" />
  <label class="pt-2">{{ 'offer.description' | translate }}</label>
  <div class="font-size-14">{{ currentCampaign.PortalDescription }}</div>
</div>
