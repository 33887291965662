<h1 class="accessibility-only">Badges</h1>

<!--Scorecard carousel-->
<div class="mt-4">
  <fgbcl-scorecard-carousel [icon]="'chevron_right'" [version2Design]="true" [phaseThree]="true"></fgbcl-scorecard-carousel>
</div>

<div class="mt-4">
  <fgbcl-scorecard-list-view [icon]="'chevron_right'" [version2Design]="true" [phaseThree]="true"></fgbcl-scorecard-list-view>
</div>

<div class="container mt-4">
  <fgbcl-badge-overview-list [designV2]="true" [phaseThree]="true"></fgbcl-badge-overview-list>
</div>

<div class="container">
  <div class="d-flex justify-content-left mt-4">
    <a class="d-flex align-items-center cursor-pointer" [routerLink]="['/rewards/wallet']">
      <span class="icon material-icons text-primary font-weight-bold font-size-16" aria-hidden="true">arrow_back_ios</span>
      <span class="font-size-14 font-weight-bold text-primary">{{ 'wallet.back.to.wallet' | translate }}</span>
    </a>
  </div>
</div>
