import { Component, OnInit, Input } from '@angular/core';
import { AnswerDisplayType, Survey } from '@fgb/core';

@Component({
  selector: 'fgb-survey-item-answer-completed-poll',
  templateUrl: './survey-completed-poll.component.html',
  styleUrls: ['./survey-completed-poll.component.scss', '../../../survey.scss'],
})
export class SurveyCompletedPollComponent implements OnInit {
  @Input() survey: Survey;
  @Input() pointsWon: number;
  answerDisplayType = AnswerDisplayType;
  constructor() {}

  ngOnInit() {}
}
