<div class="survey-container overflow-hidden rounded quiz">
  <div class="survey-header">
    <div class="image-container">
      <img *ngIf="survey.ImageURL" src="{{ survey.ImageURL | contentImage : '23' }}" alt="{{ survey.ImageURL }}" />
      <div *ngIf="!survey.ImageURL" class="h-100 d-flex flex-column justify-content-center align-items-center py-4">
        <div class="text-danger quiz-placeholder-title">{{ 'trivia.noimage.placeholder.title' | translate }}</div>
        <div class="quiz-placeholder-description font-size-12">
          {{ 'trivia.noimage.placeholder.description' | translate }}
        </div>
      </div>
      <div class="sponsor-link" *ngIf="survey.Sponsor && survey.Sponsor.SponsorImage">
        <label class="sponsor-label d-lg-block font-weight-normal font-size-10 text-white mr-lg-2 mb-0">PRESENTED BY</label>
        <a id="sponsor-link" href="{{ survey.Sponsor.SponsorLink }}" target="_blank">
          <img src="{{ survey.Sponsor.SponsorImage | contentImage: '6' }}" draggable="false" alt="" />
          <label class="accessibility-only" for="sponsor-link">Go to {{ survey.Sponsor.SponsorText }} website"</label>
        </a>
      </div>
    </div>
    <div class="survey-info text-white bg-quiz">
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <div class="icon material-icons" aria-hidden="true">live_help</div>
        </div>
        <div class="text-right" *ngIf="!survey.HasBeenAnsweredByUser">
          <span class="icon icon-clock pr-1"></span>
          <fgbcl-countdown
            [endDateTime]="survey.EndDate"
            [requestDateTime]="survey.RequestTime"
            [isUTC]="false"
            class="font-size-16 font-weight-bold"
          ></fgbcl-countdown>
        </div>
      </div>
      <div class="survey-title position-absolute font-size-16 line-height-18">
        <div>{{ survey.SurveyQuestion }}</div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="survey.ComingSoon">
    <p class="text-center">Coming soon</p>
  </ng-container>

  <ng-container *ngIf="!survey.ComingSoon">
    <ng-container *ngIf="survey.HasBeenAnsweredByUser; else answer">
      <fgb-survey-item-answer-completed-quiz [survey]="survey"></fgb-survey-item-answer-completed-quiz>
    </ng-container>
    <ng-template #answer>
      <fgb-survey-item-answer [survey]="survey"></fgb-survey-item-answer>
    </ng-template>
  </ng-container>
</div>
