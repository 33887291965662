import { Component, Input, OnInit } from '@angular/core';
import { NotificationCenterMessageView } from '@fgb/core';
import { AlertNotification } from '@fgb/portal-component-library/src/lib/club/notification';

@Component({
  selector: 'fgb-club-notification',
  templateUrl: './club-notification.component.html',
  styleUrls: ['./club-notification.component.scss'],
})
export class ClubNotificationComponent implements OnInit, AlertNotification {
  @Input() notification: NotificationCenterMessageView;
  constructor() {}

  ngOnInit(): void {}
}
