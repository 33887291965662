<div
  class="mobile-nav-menu d-flex position-fixed flex-column d-lg-none"
  *inPopulation="[populations.Default, populations.Partner]"
>
  <!-- NAV BAR -->
  <div class="mobile-nav-bar d-flex bg-primary justify-content-between">
    <a class="nav-item font-weight-bold brand p-1" [routerLink]="['/']" routerLinkActive="router-link-active">
      <label id="homeLink" class="accessibility-only">Go To Home Page</label>
      <img alt="" class="home-logo" src="assets/images/Twins_logo.svg" draggable="false" />
    </a>
    <a class="nav-item" [routerLink]="['/rewards/events']" *inPopulation="[populations.Default]">
      <span
        class="icon material-icons material-mobile-nav text-white"
        role="img"
        attr.aria-label="{{ 'nav.rewards' | translate }}"
        >local_activity</span
      >
    </a>
    <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.partners'">
      <a class="nav-item" [routerLink]="['/partners']" *inPopulation="[populations.Default]">
        <span
          class="icon material-icons material-mobile-nav text-white"
          role="img"
          attr.aria-label="{{ 'nav.account' | translate }}"
          >people</span
        >
      </a>
    </ng-container>
    <div class="nav-item" *inPopulation="[populations.Default]">
      <fgb-notification-button></fgb-notification-button>
    </div>

    <button
      attr.aria-label="{{ 'nav.toggle.screenreader' | translate }}"
      class="nav-item font-weight-bold navbar-toggler m-0 px-2"
      (click)="isCollapsed = !isCollapsed"
    >
      <span class="icon material-icons material-mobile-nav">menu</span>
    </button>
  </div>
  <!-- NAV SCREEN -->
  <div [ngbCollapse]="isCollapsed" class="mobile-nav-menu-dropdown bg-primary">
    <div class="d-flex flex-column px-4 mobile-nav-menu-content">
      <!-- Close Button -->
      <button
        class="close-button position-absolute border-0"
        attr.aria-label="{{ 'modal.close' | translate }}"
        (click)="isCollapsed = !isCollapsed"
      >
        <span class="icon font-weight-bold icon-cancel-circle"></span>
      </button>

      <!-- Accounts Menu Section -->
      <div class="d-flex align-items-center justify-content-left mt-4 mb-1">
        <img src="assets/images/Twins_logo.svg" class="brand" />
      </div>
      <div *ngIf="memberDetails$ | async as memberDetails">
        <div class="mb-0 text-uppercase text-white member-name">{{ memberDetails.FirstName }} {{ memberDetails.Surname }}</div>

        <ng-container *ngIf="memberCard$ | async as memberCard">
          <div class="text-white font-size-12 mb-2">{{ memberCard.ProductCode }} - {{ memberCard.ExternalRefNumber }}</div>
        </ng-container>
      </div>
      <div *ngIf="memberCard$ | async as memberCard">
        <hr class="my-1 bg-white w-100" />
        <ng-container *ngIf="memberCard.RetailDiscountLevel != null || memberCard.RetailDiscountLevel != undefined">
          <div class="member-details text-black pr-2 w-100 py-2">
            <div class="row mb-2 justify-content-center text-white text-left">
              <ng-container>
                <div class="col-6 border-primary">
                  <div class="card-id">
                    <label class="mb-0 font-size-14">{{ memberCard.RetailDiscountLevel | getNumber }}%</label>
                    <div class="font-size-12">{{ 'member.card.discount' | translate }}</div>
                  </div>
                </div>
              </ng-container>

              <ng-container>
                <div class="col-6 card-id px-4 border-left border-white" *ngIf="combinedCard$">
                  <label class="mb-0 font-size-14">{{ combinedCard$ | async | FortressCurrencyPipe }}</label>
                  <div class="font-size-12">{{ 'member.card.ecash.balance' | translate }}</div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <hr
          class="my-1 bg-white w-100"
          *ngIf="memberCard.RetailDiscountLevel != null || memberCard.RetailDiscountLevel != undefined"
        />
      </div>
      <!-- Nav Buttons -->
      <div class="mobile-nav-button-container font-size-14">
        <a class="nav-item" [routerLink]="['/account']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="left-side">
            <span class="icon material-icons material-mobile-dropdown mr-3" aria-hidden="true">person</span>
            <div class="nav-text">{{ 'nav.account' | translate }}</div>
          </div>
          <div class="right-side">
            <div class="material-icons icon">navigate_next</div>
          </div>
        </a>

        <a
          *inPopulation="[populations.Default]"
          class="nav-item"
          [routerLink]="['/rewards/events']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="left-side">
            <span class="icon material-icons material-mobile-dropdown mr-3" aria-hidden="true">local_activity</span>
            <div class="nav-text">{{ 'nav.rewards' | translate }}</div>
          </div>
          <div class="right-side">
            <div class="material-icons icon">navigate_next</div>
          </div>
        </a>

        <a
          *inPopulation="[populations.Default]"
          class="nav-item"
          [routerLink]="['/rewards/wallet']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="left-side">
            <span class="icon material-icons material-mobile-dropdown mr-3" aria-hidden="true">account_balance_wallet</span>
            <div class="nav-text">{{ 'rewards.nav.wallet' | translate }}</div>
          </div>
          <div class="right-side">
            <div class="material-icons icon">navigate_next</div>
          </div>
        </a>
        <a class="nav-item" [routerLink]="['/faq']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="left-side">
            <span class="icon material-icons material-mobile-dropdown mr-3" aria-hidden="true">help</span>
            <div class="nav-text">{{ 'nav.faq' | translate }}</div>
          </div>
          <div class="right-side">
            <div class="material-icons icon">navigate_next</div>
          </div>
        </a>
        <a class="nav-item" [routerLink]="['/contact']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="left-side">
            <span class="icon material-icons material-mobile-dropdown mr-3" aria-hidden="true">emoji_events</span>
            <div class="nav-text">{{ 'nav.contact.us' | translate }}</div>
          </div>
          <div class="right-side">
            <div class="material-icons icon">navigate_next</div>
          </div>
        </a>
        <a class="nav-item" [routerLink]="['/terms']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="left-side">
            <span class="icon material-icons material-mobile-dropdown mr-3" aria-hidden="true">article</span>
            <div class="nav-text">{{ 'nav.terms' | translate }}</div>
          </div>
          <div class="right-side">
            <div class="material-icons icon">navigate_next</div>
          </div>
        </a>

        <a class="nav-item text-white mb-5" (click)="logout()">
          <div class="left-side">
            <span class="icon material-icons material-mobile-dropdown mr-3" aria-hidden="true">power_settings_new</span>
            <div class="nav-text">{{ 'nav.log.out' | translate }}</div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
<!-- Non renewing -->

<div class="nr mobile-nav-menu d-flex position-fixed flex-column d-lg-none" *inPopulation="[populations.NonRenewingPopulation]">
  <div class="mobile-nav-bar d-flex bg-primary align-items-center justify-content-between">
    <a class="nav-item font-weight-bold brand p-1 nonrenew" [routerLink]="['/']" routerLinkActive="router-link-active">
      <label id="homeLink" class="accessibility-only">Go To Home Page</label>
      <img alt="" class="home-logo" src="assets/images/Twins_logo.svg" draggable="false" />
    </a>
  </div>
</div>
