<div class="banner" role="banner">
  <h1>{{ 'kids.page.title' | translate }}</h1>
</div>

<ng-container *ngIf="childList$ | async as childList">
  <div class="container">
    <div class="d-flex flex-lg-row flex-column justify-content-between align-items-center card shadow my-3 p-3">
      <div class="d-flex flex-row justify-content-center align-items-center mb-3 mb-lg-0">
        <h5 class="text-linked m-0 mr-3 px-1 font-weight-bold">{{ 'kids.page.linked' | translate }}</h5>
        <h3 class="text-count m-0 font-weight-bold">{{ childList.length }}/ {{ totalAccount }}</h3>
      </div>
      <button
        class="btn rounded text-center text-white bg-primary d-flex justify-content-center align-items-center"
        [disabled]="childList.length >= totalAccount"
        (click)="openAddChildForm(addChildInfo, childList.length >= totalAccount)"
      >
        <div class="icon svg kids-add-svg" [inlineSVG]="'assets/images/icons/partner-add.svg'"></div>
        <label class="ml-2 mb-0 font-size-14 font-weight-bold">{{ 'kids.page.btn.add' | translate }}</label>
      </button>
    </div>

    <div *ngIf="childList && childList.length">
      <div class="container mt-4">
        <div class="child-list-container">
          <div class="row">
            <div *ngFor="let child of childList" class="col-lg-4 col-12 p-0 pr-lg-3 pb-lg-3">
              <div class="card">
                <div class="d-flex flex-column pr-1 pl-3 py-2 bg-primary text-white position-relative">
                  <div class="d-flex justify-content-between mb-1">
                    <div class="d-flex align-items-center mb-2">
                      <span class="material-icons text-success" *ngIf="child.Id !== 0"> link </span>
                      <span class="material-icons" *ngIf="child.Id === 0"> link_off </span>
                      <span class="font-weight-bold font-size-14 ml-2">{{ child.Firstname }} {{ child.Lastname }}</span>
                    </div>
                    <div
                      *ngIf="child.Id === 0"
                      class="linkedkids-dropdown"
                      display="dynamic"
                      placement="bottom-end"
                      ngbDropdown
                      #ddLinkedKids="ngbDropdown"
                      focusTab
                    >
                      <span
                        ngbDropdownToggle
                        id="linkedkidsdropdown"
                        class="cursor-pointer linkedkids-dropdown-btn color-white material-icons"
                      >
                        more_horiz
                      </span>
                      <div
                        class="dropdown-menu dropdown-menu-end bg-white shadow rounded"
                        ngbDropdownMenu
                        (click)="closeDropDown(ddLinkedKids)"
                        aria-labelledby="linkedkidsdropdown"
                      >
                        <button
                          class="btn text-danger d-flex align-items-center bg-white my-2"
                          (click)="unlink(child)"
                          *ngIf="child.Id === 0"
                        >
                          <span class="material-icons mr-2"> cancel </span>
                          <span class="font-size-14">{{ 'kids.page.btn.cancel' | translate }}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center mb-4 font-size-12">
                    <span class="mr-2">{{ 'kids.page.card.memberid' | translate }} </span>
                    <span>{{ child.MemberId }}</span>
                  </div>
                  <div class="d-flex align-items-center mb-2 font-size-12">
                    <span class="font-weight-bold mr-2">{{ 'kids.page.card.birthday' | translate }} </span>
                    <span>{{ child.DateOfBirth | date : 'MMM dd, yyyy' }} </span>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center mx-auto my-3">
                  <button class="btn btn-success font-weight-bold" *ngIf="child.Id !== 0" (click)="loginToChild(child)">
                    {{ 'kids.page.btn.login' | translate }}
                  </button>
                  <button
                    class="btn btn-primary font-weight-bold"
                    *ngIf="child.Id === 0"
                    (click)="openEnterCode(enterCode, child)"
                  >
                    {{ 'kids.page.btn.enter.code' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #addChildInfo let-modal id="add-child-modal">
  <div class="container p-3">
    <div class="d-flex justify-content-center align-items-center mt-2 mb-4">
      <div class="d-flex align-items-center font-weight-bold">
        <span class="material-icons mr-2"> account_circle </span>
        {{ 'kids.page.modal.add.title' | translate }}
      </div>
    </div>
    <hr />
    <fgbcl-errors></fgbcl-errors>
    <form [formGroup]="addChildForm" class="mb-4">
      <div class="form-row">
        <div class="form-group col-lg-6">
          <label for="LastName">{{ 'kids.page.modal.add.label.lastname' | translate }}</label>
          <input
            id="LastName"
            type="text"
            class="form-control border-0 bg-muted"
            formControlName="LastName"
            placeholder="Enter Here"
          />
        </div>
        <div class="form-group col-lg-6">
          <div class="form-group date-range-picker">
            <label for="DateOfBirth" (click)="dobDatePicker.toggle()">{{
              'kids.page.modal.add.label.birthday' | translate
            }}</label>
            <div class="input-group d-flex">
              <input
                id="DateOfBirth"
                class="form-control border-0 bg-muted"
                name="DateOfBirth"
                type="text"
                formControlName="DateOfBirth"
                ngbDatepicker
                #dobDatePicker="ngbDatepicker"
                (click)="dobDatePicker.toggle()"
                (focus)="changeModalHeight()"
                autocomplete="off"
              />
              <div class="input-group-append">
                <button
                  class="btn icon icon-calendar bg-muted font-weight-bold py-0 border-0"
                  type="button"
                  (click)="dobDatePicker.toggle()"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-lg-6">
          <label for="MemberId">{{ 'kids.page.modal.add.label.memberid' | translate }}</label>
          <input
            id="MemberId"
            type="text"
            class="form-control border-0 bg-muted"
            formControlName="MemberId"
            placeholder="Enter Here"
          />
        </div>
      </div>
    </form>
    <div class="d-flex justify-content-between mt-3">
      <button class="btn btn-modal btn-previous-step mb-0" (click)="modal.close()">
        {{ 'kids.page.modal.add.btn.cancel' | translate }}
      </button>
      <button
        class="btn btn-modal btn-next-step mb-0"
        (click)="sendRequest()"
        [disabled]="!addChildForm.value.LastName || !addChildForm.value.MemberId || !addChildForm.value.DateOfBirth"
      >
        {{ 'kids.page.modal.add.btn.send' | translate }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #enterCode let-modal>
  <div class="container p-3 overflow-auto">
    <div class="d-flex justify-content-center align-items-center">
      <div class="d-flex align-items-center font-weight-bold">
        <span class="material-icons mr-2"> more_horiz </span>
        {{ 'kids.page.modal.enter.code.title' | translate }}
      </div>
    </div>
    <hr />
    <fgbcl-errors></fgbcl-errors>
    <div class="d-flex justify-content-center align-items-center flex-column mt-3 mb-5">
      <span class="text-center font-size-14">{{ 'kids.page.modal.enter.code.description1' | translate }} </span>
      <span class="text-center font-size-14">{{ 'kids.page.modal.enter.code.description2' | translate }}</span>
      <div class="d-flex flex-column my-5 w-100">
        <label class="font-weight-bold font-size-12" for="linkCode">{{ 'kids.page.modal.enter.code.label' | translate }}</label>
        <input id="linkCode" type="text" class="form-control" placeholder="Enter Here" [(ngModel)]="linkCode" />
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <button class="btn btn-modal btn-previous-step mb-0" (click)="modal.close()">
        {{ 'kids.page.modal.enter.code.btn.cancel' | translate }}
      </button>
      <button class="btn btn-modal btn-next-step mb-0" (click)="confirmLink()">
        {{ 'kids.page.modal.enter.code.btn.confirm' | translate }}
      </button>
    </div>
  </div>
</ng-template>
