<div class="barcode-component w-100 d-block d-lg-none border rounded" *ngIf="memberCard$ | async as memberCard">
  <div class="border-0" *ngIf="barcode$ | async as barcode">
    <div class="rounded modal-body p-0 flex-column d-flex flex-wrap justify-content-around">
      <div class="member-details pr-2 w-100 rounded-0 text-center">
        <div class="mt-2 font-weight-bold text-primary text-uppercase card-name" *ngIf="memberDetails$ | async as memberDetails">
          {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
        </div>
        <ng-container>
          <div class="text-black font-size-12">{{ memberCard.ProductCode }} - {{ memberCard.ExternalRefNumber }}</div>
        </ng-container>
      </div>

      <!--Barcode-->
      <div class="qr py-2 bg-white mb-3 text-center">
        <qrcode [qrdata]="barcode.Barcode" [size]="84" [level]="'M'" [usesvg]="true"></qrcode>
      </div>

      <!--Member Details-->

      <ng-container *ngIf="memberCard.RetailDiscountLevel != null || memberCard.RetailDiscountLevel != undefined" ;else default>
        <div
          class="member-details text-black pr-2 w-100"
          [ngClass]="{
            'bg-primary text-white border-white': memberCard.RetailDiscountLevel.includes(20),
            'bg-secondary text-white border-white': memberCard.RetailDiscountLevel.includes(15),
            'bg-gold text-black border-black': memberCard.RetailDiscountLevel.includes(40),
            'bg-grey text-black border-black': memberCard.RetailDiscountLevel.includes(10)
          }"
        >
          <div class="row mb-2 justify-content-center">
            <ng-container>
              <div
                class="col-6 border-primary"
                [ngClass]="{
                  'text-white border-right':
                    memberCard.RetailDiscountLevel.includes(15) || memberCard.RetailDiscountLevel.includes(20),
                  'text-black border-right-black':
                    memberCard.RetailDiscountLevel.includes(40) || memberCard.RetailDiscountLevel.includes(10)
                }"
              >
                <div class="card-id text-center">
                  <label class="mb-0 font-size-14">{{ memberCard.RetailDiscountLevel | getNumber }}%</label>
                  <div class="font-size-12">{{ 'member.card.discount' | translate }}</div>
                </div>
              </div>
            </ng-container>

            <ng-container>
              <div class="col-6 card-id text-center mt-1 px-4" *ngIf="combinedCard$">
                <label class="mb-0 font-size-14">{{ combinedCard$ | async | FortressCurrencyPipe }}</label>
                <div class="font-size-12">{{ 'member.card.ecash.balance' | translate }}</div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="px-3 py-2">
      <fgbcl-cash-back-toggle></fgbcl-cash-back-toggle>
    </div>
  </div>
</div>
<ng-template #default>
  <div class="member-details text-black pr-2 w-100 border-style-dkb bg-primary">
    <div class="row mb-2 justify-content-center">
      <ng-container>
        <div class="col-6 border-primary text-white border-right">
          <div class="card-id text-center">
            <label class="mb-0 font-size-14">{{ memberCard.RetailDiscountLevel | getNumber }}%</label>
            <div class="font-size-12">{{ 'member.card.retaildiscountlevel' | translate }}</div>
          </div>
        </div>
      </ng-container>

      <ng-container>
        <div class="col-6 card-id text-center mt-1 px-4">
          <label class="mb-0 font-size-14">{{ memberCard.DiscountLevel | getNumber }}%</label>
          <div class="font-size-12">{{ 'member.card.discountlevel' | translate }}</div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
