import { Component, OnInit } from '@angular/core';
import { CardLinkOfferOptions, 
         CardLinkOfferType, 
         Company, 
         UserContext, 
         CardLinkOffersQuery } from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';

@Component({
  selector: 'fgb-card-link-all-offers-page',
  templateUrl: './card-link-all-offers-page.component.html',
  styleUrls: ['./card-link-all-offers-page.component.scss'],
})
export class CardLinkAllOffersPageComponent implements OnInit {
  userContext: UserContext;
  searchText: string = '';
  categorytagID: number = 0;
  screenType = ScreenType;
  cardLinkOfferOptions: CardLinkOfferOptions;

  constructor(private cardLinkOffersQuery: CardLinkOffersQuery) {}

  ngOnInit(): void {
    this.getOffers();
  }

  getOffers() {
    if (this.categorytagID == 0 && this.searchText == '') {
      this.cardLinkOfferOptions = {
        offerType: CardLinkOfferType.AllOffers,
        limit: 0,
        showMoreButton: true,
        showMoreIncrement: 4,
      };
    } else {
      this.cardLinkOfferOptions = {
        offerType: CardLinkOfferType.CustomOffersData,
        customOffersData: this.cardLinkOffersQuery.searchOffer(this.searchText, this.categorytagID),
      };
    }
  }

  navigateToFirstOfferFromAffiliate(company: Company) {
    this.cardLinkOffersQuery.navigateToCompaniesFirstOffer(company.CompanyId).subscribe().unsubscribe();
  }
}
