<div class="mob">
  <nav class="nav nav-secondary mixed-loyalty-nav my-0" *ifScreenSize="screenType.Mobile">
    <a
      class="nav-item"
      [routerLink]="['/rewards/events']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
      attr.aria-label="{{ 'rewards.nav.events.screenreader' | translate }}"
    >
      <fgbcl-loyalty-icon [loyaltyType]="3"></fgbcl-loyalty-icon>
      <label>{{ 'rewards.nav.events' | translate: { fgbdefault: 'Events' } }}</label>
    </a>

    <a
      class="nav-item"
      [routerLink]="['/rewards/wallet']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
      attr.aria-label="{{ 'rewards.nav.wallet.screenreader' | translate: { fgbdefault: 'Move to Wallet page' } }}"
    >
      <fgbcl-loyalty-icon [loyaltyType]="'wallet'"></fgbcl-loyalty-icon>
      <label>{{ 'rewards.nav.wallet' | translate }}</label>
    </a>
  </nav>
</div>
<nav class="dkt nav nav-secondary mixed-loyalty-nav my-0" *ifScreenSize="screenType.Desktop">
  <a
    class="nav-item"
    [routerLink]="['/rewards/events']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'rewards.nav.events.screenreader' | translate }}"
  >
    <fgbcl-loyalty-icon [loyaltyType]="3"></fgbcl-loyalty-icon>
    <label>{{ 'rewards.nav.events' | translate: { fgbdefault: 'Events' } }}</label>
  </a>

  <a
    class="nav-item"
    [routerLink]="['/rewards/wallet']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'rewards.nav.wallet.screenreader' | translate: { fgbdefault: 'Move to Wallet page' } }}"
  >
    <fgbcl-loyalty-icon [loyaltyType]="'wallet'"></fgbcl-loyalty-icon>
    <label>{{ 'rewards.nav.wallet' | translate }}</label>
  </a>
</nav>
