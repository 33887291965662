import { Component, OnInit } from '@angular/core';
import { ChildQuery, ChildService, ChildViewModel } from '@fgb/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { UpgradeChildrenComponent } from '../upgrade-children/upgrade-children.component';
import { OwlCarouselSettings } from 'src/app/shared/utilities/owl-carousel-settings';

@Component({
  selector: 'fgb-child-list',
  templateUrl: 'child-list.component.html',
  styleUrls: ['child-list.component.scss'],
})
export class ChildListComponent implements OnInit {
  children$: Observable<any>;
  customOptions: OwlOptions = {
    margin: OwlCarouselSettings.Margin,
    pullDrag: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: { items: 1 },
      400: { items: 1 },
      740: { items: 3 },
    },
  };
  loaded$: Observable<boolean>;

  constructor(private childQuery: ChildQuery, private childService: ChildService, private modalService: NgbModal) {}

  ngOnInit() {
    this.childService.fetchChildren().toPromise();
    this.childService.fetchChildCountLimit().toPromise();

    this.children$ = this.childQuery.getChildrenInfo();
    this.loaded$ = this.childQuery.isLoaded$;
  }

  upgradeChildren(freeChildren: ChildViewModel[]) {
    const modal = this.modalService.open(UpgradeChildrenComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'upgrade-children-modal',
      size: 'lg',
    });
    modal.componentInstance.children = freeChildren;
  }
}
