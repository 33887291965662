<div class="banner" role="banner">
  <h1>{{ 'games.page.pick.player.title' | translate }}</h1>
</div>

<fgb-game-nav></fgb-game-nav>
<div class="container">
  <ng-container
    *ngIf="
      {
        pendingGames: pendingGames$ | async,
        currentGames: currentGames$ | async
      } as games;
      else noGames
    "
  >
    <ng-container *ngIf="!!games.currentGames.length || !!games.pendingGames.length; else noGames">
      <ng-container *ngIf="games.currentGames && !!games.currentGames.length">
        <div class="row">
          <div class="col-lg-6 col-12">
            <fgb-fantasy-game-panel
              [game]="games.currentGames[0]"
              (button)="goToDetail(games.currentGames[0].GameId)"
              [buttonState]="hasActivePlayer(games.currentGames[0]) ? 'player-link' : 'no-player-link'"
            ></fgb-fantasy-game-panel>
          </div>
          <div class="col-lg-6 col-12 d-lg-block d-none">
            <div *ngIf="games.currentGames[1]; else noGame">
              <fgb-fantasy-game-panel-next-game [game]="games.currentGames[1]"> </fgb-fantasy-game-panel-next-game>
            </div>
            <ng-template #noGame>
              <div class="no-game-panel card shadow border-0 d-flex align-items-center justify-content-center">
                <h4 class="text-primary">{{ 'pick.player.game.panel.placeholder' | translate }}</h4>
              </div>
            </ng-template>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <div class="row">
    <div class="col-lg-6 col-12">
      <h5 class="text-left mt-5 mb-3">{{ 'pick.player.history.title' | translate }}</h5>
      <fgb-fantasy-history-list> </fgb-fantasy-history-list>
    </div>

    <div class="col-lg-6 col-12">
      <h5 class="text-left mt-5 mb-3">{{ 'pick.player.leaderboard.title' | translate }}</h5>
      <div class="card leaderboard-card rounded border-0 shadow">
        <div class="nav d-flex align-items-center justify-content-center">
          <a class="nav-item w-25 text-center" [ngClass]="{ selected: showMonthly }" (click)="showMonthly = true">
            <label class="font-size-12 font-weight-bold text-center">{{ 'pick.player.leaderboard.monthly' | translate }}</label>
          </a>
          <a class="nav-item w-25 text-center" [ngClass]="{ selected: !showMonthly }" (click)="showMonthly = false">
            <label class="font-size-12 font-weight-bold text-center">{{ 'pick.player.leaderboard.yearly' | translate }}</label>
          </a>
        </div>
        <div class="leaderboard-nav" *ngIf="showMonthly; else yearly">
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide *ngFor="let item of monthlyLeaderboard">
              <div class="py-2 bg-muted leaderboard-date-toggle">
                <h2 class="text-center font-weight-bold font-size-14 m-0">{{ item.month }}</h2>
              </div>
              <fgb-fantasy-leaderboard [fantasyEntries]="item.entries"></fgb-fantasy-leaderboard>
            </ng-template>
          </owl-carousel-o>
        </div>
        <ng-template #yearly>
          <div class="py-2 bg-muted mt-1">
            <h2 class="text-center font-weight-bold font-size-14 m-0">{{ year }}</h2>
          </div>
          <fgb-fantasy-leaderboard [fantasyEntries]="yearlyLeaderboards"></fgb-fantasy-leaderboard>
        </ng-template>
        <div class="leaderboard-button text-center w-100">
          <a class="font-weight-bold text-info font-size-14" [routerLink]="['/fantasyleaderboard']">{{
            'pick.player.view.full.leaderboard' | translate
          }}</a>
        </div>
      </div>
    </div>
  </div>

  <!--Placeholder Information-->
  <ng-template #noGames>
    <div class="no-items w-100 text-center">
      <div class="icon material-icons-two-tone no-results-placeholder-icon text-center" aria-hidden="true">
        supervised_user_circle
      </div>
      <h5 class="text-primary text-center mb-0 font-weight-bold text-uppercase">
        {{ 'pick.player.placeholder.title' | translate }}
      </h5>
      <p class="text-info text-center font-size-14">{{ 'pick.player.placeholder.description' | translate }}</p>
    </div>
  </ng-template>

  <!--Rules-->
  <h5 class="text-left mt-5">{{ 'pick.player.rules.title' | translate }}</h5>

  <div class="col-12 games-rules-container mt-lg-3">
    <div class="row">
      <ngb-accordion #a="ngbAccordion" [destroyOnHide]="false" class="mt-3 w-100 mt-lg-0">
        <!--Q1-->
        <ngb-panel id="ngb-panel-0">
          <ng-template ngbPanelHeader let-opened="opened">
            <div class="row bg-muted">
              <div class="col-md-1 col-2 d-flex">
                <div>
                  <button
                    class="btn py-1 pt-1"
                    type="button"
                    attr.aria-label="{{ 'pick.player.see.details' | translate }}"
                    ngbPanelToggle
                  >
                    <div>
                      <div class="icon icon-arrow-1-down text-grey font-weight-bold d-flex align-items-center"></div>
                    </div>
                  </button>
                </div>
              </div>
              <div class="col-10 faq-question-text font-weight-bold text-grey pl-0 pt-1" (click)="a.toggle('ngb-panel-0')">
                <div class="earn-question-titles">
                  {{ 'pick.player.rules1.title' | translate }}
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template ngbPanelContent class="border-muted">
            <div class="row py-0">
              <div class="content col-12">
                <div class="card card-body fantasy text-grey">
                  <div class="font-size-12" [innerHTML]="'pick.player.rules1.description' | translate | markdownTranslate"></div>
                </div>
              </div>
            </div>
          </ng-template>
        </ngb-panel>

        <!--Q2-->
        <ngb-panel id="ngb-panel-1">
          <ng-template ngbPanelHeader let-opened="opened">
            <div class="row bg-muted">
              <div class="col-md-1 col-2 d-flex">
                <div>
                  <button
                    class="btn py-1"
                    type="button"
                    attr.aria-label="{{ 'pick.player.see.details' | translate }}"
                    ngbPanelToggle
                  >
                    <div>
                      <div class="icon icon-arrow-1-down text-grey font-weight-bold d-flex align-items-center"></div>
                    </div>
                  </button>
                </div>
              </div>
              <div class="col-10 faq-question-text font-weight-bold text-grey pl-0 pt-1" (click)="a.toggle('ngb-panel-1')">
                <div class="earn-question-titles">
                  {{ 'pick.player.rules2.title' | translate }}
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="row">
              <div class="content col-12">
                <div class="card card-body fantasy text-grey">
                  <div class="font-size-12" [innerHTML]="'pick.player.rules2.description' | translate | markdownTranslate"></div>
                </div>
              </div>
            </div>
          </ng-template>
        </ngb-panel>
        <!--Q3-->
        <ngb-panel id="ngb-panel-2">
          <ng-template ngbPanelHeader let-opened="opened">
            <div class="row bg-muted">
              <div class="col-md-1 col-2 d-flex">
                <div>
                  <button
                    class="btn py-1"
                    type="button"
                    attr.aria-label="{{ 'pick.player.see.details' | translate }}"
                    ngbPanelToggle
                  >
                    <div>
                      <div class="icon icon-arrow-1-down text-grey font-weight-bold d-flex align-items-center"></div>
                    </div>
                  </button>
                </div>
              </div>
              <div class="col-10 faq-question-text font-weight-bold text-grey pl-0 pt-1" (click)="a.toggle('ngb-panel-2')">
                <div class="earn-question-titles">
                  {{ 'pick.player.rules3.title' | translate }}
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="row">
              <div class="content col-12">
                <div class="card card-body fantasy text-grey">
                  <div class="font-size-12" [innerHTML]="'pick.player.rules3.description' | translate | markdownTranslate"></div>
                </div>
              </div>
            </div>
          </ng-template>
        </ngb-panel>

        <!--Q4-->
        <ngb-panel id="ngb-panel-3">
          <ng-template ngbPanelHeader let-opened="opened">
            <div class="row bg-muted">
              <div class="col-md-1 col-2 d-flex">
                <div>
                  <button
                    class="btn py-1"
                    type="button"
                    attr.aria-label="{{ 'pick.player.see.details' | translate }}"
                    ngbPanelToggle
                  >
                    <div>
                      <div class="icon icon-arrow-1-down text-grey font-weight-bold d-flex align-items-center"></div>
                    </div>
                  </button>
                </div>
              </div>
              <div class="col-10 faq-question-text font-weight-bold text-grey pl-0 pt-1" (click)="a.toggle('ngb-panel-3')">
                <div class="earn-question-titles">
                  {{ 'pick.player.rules4.title' | translate }}
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="row">
              <div class="content col-12">
                <div class="card card-body fantasy text-grey">
                  <div class="font-size-12" [innerHTML]="'pick.player.rules4.description' | translate | markdownTranslate"></div>
                </div>
              </div>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
  </div>
</div>
