<div class="banner" role="banner" *ifScreenSize="screenType.Desktop">
  <h1 class="text-white">{{ 'events.page.title' | translate }}</h1>
</div>
<ng-container *ifScreenSize="screenType.Mobile">
  <div>
    <div class="container my-3">
      <fgb-barcode></fgb-barcode>
    </div>
  </div>
</ng-container>

<fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>

<div class="container mt-3">
  <fgbcl-marketplace-list
    [isList]="true"
    [showSortBar]="false"
    [marketplaceItemTypes]="[3, 5]"
    [eventItemView]="true"
  ></fgbcl-marketplace-list>
</div>
