import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Survey } from '@fgb/core';

import { SurveyItemAnswerComponent } from '../../survey-item-answer/survey-item-answer.component';

@Component({
  selector: 'fgb-survey-item-predictor',
  templateUrl: './survey-item-predictor.component.html',
  styleUrls: ['./survey-item-predictor.component.scss', '../../survey.scss'],
})
export class SurveyItemPredictorComponent implements OnInit {
  @Input() survey: Survey;
  @Input() hideTitle: boolean = false;
  @ViewChild('itemAnswer') itemAnswer: SurveyItemAnswerComponent;
  disableSubmitButton = true;
  constructor() {}

  ngOnInit() {}

  submitAnswer() {
    this.itemAnswer.submitAnswer();
  }

  hasUserAnswered() {
    if (this.itemAnswer.selectedAnswers.length > 0) {
      this.disableSubmitButton = false;
    } else {
      this.disableSubmitButton = true;
    }
  }
}
