<div class="card b-0 mt-3 mt-lg-0 border rounded">
  <div class="card-body p-3">
    <div class="row">
      <div class="col-12 col-lg-7">
        <fgbcl-date-range-picker [(startDate)]="transactionStartDate" [(endDate)]="transactionEndDate"></fgbcl-date-range-picker>
      </div>
      <div class="col-12 col-lg-2 offset-lg-3">
        <button
          type="button"
          class="search-button font-weight-bold mb-2 d-block mx-auto border-0 text-white bg-primary w-100"
          (click)="search()"
        >
          {{ 'trans.history.search.button' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<fgb-transaction-list
  *ngIf="selectedValues"
  [startDate]="selectedValues.start"
  [endDate]="selectedValues.end"
  [memberId]="memberId"
  [transactionType]="selectedValues.type"
></fgb-transaction-list>
