<!--nav arena cash-->
<ul class="nav nav-secondary mixed-loyalty-nav mt-0 mb-3">
  <a
    class="nav-item btn"
    [routerLink]="['/arenacashtransfer']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <span class="icon material-icons">send</span>
    <label>{{ 'nav.transfer.credit' | translate }}</label>
  </a>
  <a
    class="nav-item btn"
    [routerLink]="['/ecashhistory']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <span class="icon material-icons">history</span>
    <label>History</label>
  </a>
</ul>
<!--EO: nav arena cash -->
