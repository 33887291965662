<h5 class="mt-3 mb-2">{{ 'auction.page.title' | translate }}</h5>
<div class="row" *ngIf="auctionBids$ | async as auctionBids">
  <ng-container *ngIf="auctionBids.length">
    <a *ngFor="let item of auctionBids" routerLink="/rewards/bids/details/{{ item.Id }}" class="col-md-6 col-lg-4">
      <div class="loyalty-item-container">
        <fgbcl-auction-bid-list-item [auctionBid]="item"></fgbcl-auction-bid-list-item>
      </div>
    </a>
  </ng-container>
</div>
