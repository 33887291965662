<div class="banner" role="banner">
  <h1>{{ 'points.management.page.title' | translate }}</h1>
</div>

<fgb-account-nav></fgb-account-nav>

<div class="container">
  <fgb-points-management-placeholder> </fgb-points-management-placeholder>
</div>
<div class="title">
  <h4 class="text-uppercase text-center mt-lg-5">Points Transfer</h4>
</div>
<div class="container">
  <fgb-points-transfer> </fgb-points-transfer>
</div>
