<div class="d-none d-lg-block">
  <div class="banner" role="banner" *ifScreenSize="screenType.Desktop">
    <h1 class="text-white">{{ 'wallet.page.title' | translate }}</h1>
  </div>
</div>

<ng-container *ifScreenSize="screenType.Mobile">
  <div class="container my-3">
    <fgb-barcode></fgb-barcode>
  </div>
</ng-container>

<fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>

<!-- Campaign Offers -->
<div class="offers-container bg-primary py-3 mb-3" [class.d-none]="campaignOffersList.length === 0">
  <fgbcl-campaigns-offers-list (dataChange)="onCampaignOfferListChange($event)"></fgbcl-campaigns-offers-list>
</div>
<!-- V2 Wallet -->
<ng-container
  *ngIf="
    {
      walletItemsUnclaimed: walletItemsUnclaimed$ | async
    } as walletItems;
    else noWalletItems
  "
>
  <div class="wallet-redeem-carousel pt-4">
    <div class="container">
      <ng-container *ngIf="walletItems.walletItemsUnclaimed && walletItems.walletItemsUnclaimed.length; else noWalletItems">
        <fgbcl-rewards-wallet-redeem-list [unRedeemedItems]="walletItems.walletItemsUnclaimed"></fgbcl-rewards-wallet-redeem-list>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #noWalletItems>
  <div class="container">
    <h5 class="no-wallet-items mt-4 mb-3 font-weight-bold">{{ 'rewards.wallet.no.items' | translate }}</h5>
    <div class="border">
      <div class="no-wallet-items-description font-weight-bold font-size-12 text-center text-grey py-3">
        {{ 'rewards.wallet.no.items.description' | translate }}
      </div>
    </div>
  </div>
</ng-template>

<div class="container mt-3 text-center">
  <a class="font-size-14 claimed-text font-weight-bold" [routerLink]="['/rewards/claimed']">
    {{ 'rewards.wallet.view.claimed' | translate }}
  </a>
</div>
