<div *ngIf="purchasedItem; else noPurchase" class="container">
  <!-- Details -->
  <div class="border rounded item-details d-flex flex-column justify-content-between flex-grow-1 flex-shrink-1 p-3">
    <div>
      <div class="text-success mb-0 font-weight-bold text-uppercase font-size-14 text-center">
        <ng-container *ngIf="marketplaceItemType == 'Marketplace'">{{ 'marketplace.purchase.success' | translate }}</ng-container>
        <ng-container *ngIf="marketplaceItemType == 'Event'">{{ 'events.purchase.success' | translate }}</ng-container>
        <ng-container *ngIf="marketplaceItemType == 'Product'">{{ 'products.purchase.success' | translate }}</ng-container>
      </div>
      <hr class="my-1 bg-white w-100 mt-3 mb-4" />
      <p class="mb-0 text-black font-size-14 text-center">
        <ng-container *ngIf="marketplaceItemType == 'Marketplace'">{{
          'marketplace.purchase.email.confirmation' | translate
        }}</ng-container>
        <ng-container *ngIf="marketplaceItemType == 'Event'">{{ 'events.purchase.email.confirmation' | translate }}</ng-container>
        <ng-container *ngIf="marketplaceItemType == 'Product'">{{
          'products.purchase.email.confirmation' | translate
        }}</ng-container>
      </p>

      <div class="row my-5">
        <div class="col-lg-1 col-2 d-flex justify-content-left">
          <img
            class="purchase-success-image shadow rounded"
            src="{{ purchasedItem.ItemImageURL | contentImage: '7':'marketplace' }}"
            alt="{{ purchasedItem.ImageURL }}"
            [useDefault]="true"
            loyaltyType="marketplace"
            draggable="false"
          />
        </div>
        <div class="col-lg-10 col-10">
          <div class="pl-lg-3 pl-1">
            <div class="font-size-12 mb-1">{{ purchasedItem.DisplayName }}</div>
            <div class="row">
              <!--Attributes-->
              <ng-container *ngFor="let attribute of attributes">
                <div class="col-12 mt-1 mb-2">
                  <div class="font-size-12 mb-0">
                    <span class="font-weight-bold">{{ attribute.AttributeName }}</span>
                    <span> {{ attribute.ValueName }}</span>
                  </div>
                </div>
              </ng-container>
              <!--Custom Fields-->
              <ng-container *ngFor="let customfield of customFields">
                <div class="col-12 mb-2">
                  <div class="font-size-12 mb-0">
                    <span class="font-weight-bold">{{ customfield.DisplayName }}:</span>
                    <span> {{ customfield.Value }}</span>
                  </div>
                </div>
              </ng-container>
              <!--Quantity-->
              <div class="col-4 col-lg-3">
                <div class="font-size-12 mb-0">
                  <span class="font-weight-bold pr-2">{{ 'marketplace.quantity' | translate }}</span>
                  <span>{{ quantity }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mx-auto mb-2 border-0" *ngIf="purchasedItem.ProcessProviderId == ProcessProvider.Download">
      <a
        class="d-flex align-items-center justify-content-center btn btn-primary confirm-btn font-weight-bold"
        (click)="downloadPurchasedItem(purchasedItemLogId)"
      >
        <span class="icon material-icons mr-1 purchased-download-icon">download_for_offline</span>
        <span class="font-size-14">{{ 'download.rewards.item' | translate }}</span>
      </a>
    </div>

    <div class="d-flex justify-content-between border-0" *ifScreenSize="screenType.Desktop">
      <a class="btn btn-primary dkt-confirm-btn font-weight-bold" [routerLink]="['/rewards/events']">{{
        'rewards.back.to.events' | translate
      }}</a>
      <a class="btn btn-wallet text-white dkt-confirm-btn font-weight-bold" [routerLink]="['/rewards/wallet']">{{
        'rewards.back.to.wallet' | translate
      }}</a>
    </div>
    <div class="col-12 col-lg-6" *ifScreenSize="screenType.Mobile">
      <a class="btn btn-primary confirm-btn font-weight-bold mb-3" [routerLink]="['/rewards/events']">{{
        'rewards.back.to.events' | translate
      }}</a>
      <a class="btn btn-wallet text-white confirm-btn font-weight-bold" [routerLink]="['/rewards/wallet']">{{
        'rewards.back.to.wallet' | translate
      }}</a>
    </div>
  </div>
</div>

<ng-template #noPurchase>
  <div class="text-center m-5">{{ 'no.purchased.item.found' | translate }}</div>
</ng-template>
