<div class="d-block">
  <ul class="nav nav-secondary mixed-loyalty-nav">
    <a
      class="nav-item reward-nav-item"
      [routerLink]="['/games']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
      attr.aria-label="{{ 'game.nav.games.screenreader' | translate: { fgbdefault: 'Move to Games page' } }}"
    >
      <div class="icon material-icons" aria-hidden="true">extension</div>
      <label>{{ 'games.nav.featured' | translate }}</label>
    </a>
    <a
      class="nav-item reward-nav-item"
      [routerLink]="['/games/quiz']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
      attr.aria-label="{{ 'game.nav.quiz.screenreader' | translate: { fgbdefault: 'Move to Trivia page' } }}"
    >
      <div class="icon material-icons" aria-hidden="true">live_help</div>
      <label>{{ 'games.nav.trivia' | translate }}</label>
    </a>
    <a
      class="nav-item reward-nav-item"
      [routerLink]="['/games/jokes']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
      attr.aria-label="{{ 'game.nav.jokes.screenreader' | translate: { fgbdefault: 'Move to Jokes page' } }}"
    >
      <div class="icon material-icons" aria-hidden="true">emoji_emotions</div>
      <label>{{ 'games.nav.jokes' | translate }}</label>
    </a>
    <a
      class="nav-item reward-nav-item"
      [routerLink]="['/games/predictor']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
        attr.aria-label="{{ 'game.nav.predictor.screenreader' | translate: { fgbdefault: 'Move to Predictor page' } }}"
    >
      <div class="icon material-icons" aria-hidden="true">flaky</div>
      <label>{{ 'games.nav.predictor' | translate }}</label>
    </a>
    <a
      class="nav-item reward-nav-item"
      [routerLink]="['/games/scorepredictor']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
        attr.aria-label="{{ 'game.nav.score.predictor.screenreader' | translate: { fgbdefault: 'Move to Score Predictor page' } }}"
    >
      <div class="icon material-icons" aria-hidden="true">scoreboard</div>
      <label>{{ 'games.nav.score.predictor' | translate }}</label>
    </a>
    <a
      class="nav-item reward-nav-item"
      [routerLink]="['/games/polls']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
        attr.aria-label="{{ 'game.nav.polls.screenreader' | translate: { fgbdefault: 'Move to Polls page' } }}"
    >
      <div class="icon material-icons" aria-hidden="true">assessment</div>
      <label>{{ 'games.nav.polls' | translate }}</label>
    </a>
    <a
      class="nav-item reward-nav-item"
      [routerLink]="['/games/pickaplayer']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
        attr.aria-label="{{ 'game.nav.pick.a.player.screenreader' | translate: { fgbdefault: 'Move to Pick a Player page' } }}"
    >
      <div class="icon material-icons" aria-hidden="true">supervised_user_circle</div>
      <label>{{ 'games.nav.pick.player' | translate }}</label>
    </a>
  </ul>
</div>
