import { Component, OnInit } from '@angular/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
@Component({
  selector: 'fgb-mixed-loyalty-nav',
  templateUrl: './mixed-loyalty-nav.component.html',
  styleUrls: ['./mixed-loyalty-nav.component.scss'],
})
export class MixedLoyaltyNavComponent implements OnInit {
  constructor() {}
  screenType = ScreenType;
  ngOnInit() {}
}
