import { Component, Input, OnInit } from '@angular/core';
import { EntityNotificationGroup, EntityNotificationGroupDto, MemberQuery, NotificationManagementService } from '@fgb/core';

@Component({
  selector: 'fgb-notification-group-item',
  templateUrl: './notification-group-item.component.html',
  styleUrls: ['./notification-group-item.component.scss'],
})
export class NotificationGroupItemComponent implements OnInit {
  @Input() group: EntityNotificationGroup;
  portalId: string;

  constructor(private memberContext: MemberQuery, private notificationManagementService: NotificationManagementService) {}

  ngOnInit() {
    this.portalId = this.memberContext.getUserContext().portalId;
  }

  updateNotificationGroup(notificationGroupId: number, active: boolean, entityNotificationGroupDto?: number) {
    let dto = new EntityNotificationGroupDto(notificationGroupId, this.portalId, active, entityNotificationGroupDto);

    this.notificationManagementService.updateGroup(dto);
  }
}
