import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SplitTransactionComponent } from './split-transaction-item/split-transaction.component';
import { SplitTransactionListComponent } from './split-transaction-list/split-transaction-list.component';
import { PipesModule } from '@fgb/portal-component-library/src/lib/pipes';
import { LoadingModule } from '@fgb/portal-component-library/src/lib/shared/loading';
import { SplitTransactionHistoryComponent } from './split-transaction-history/split-transaction-history.component';
import { DateRangePickerModule } from '@fgb/portal-component-library/src/lib/shared/date-range-picker';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        SplitTransactionComponent,
        SplitTransactionListComponent,
        SplitTransactionHistoryComponent
    ],
    imports: [
        CommonModule,
        PipesModule,
        LoadingModule,
        DateRangePickerModule,
        TranslateModule
    ],
    exports: [
        SplitTransactionComponent,
        SplitTransactionListComponent,
        SplitTransactionHistoryComponent
    ]
})
export class SplitTransactionModule{}