<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  <div class="form-group font-weight-bold input-info">
    <label class="field-title mb-2 font-weight-bold" for="username">{{ 'login.page.member.description' | translate }} </label>

    <input
      type="text"
      formControlName="username"
      placeholder="Enter here"
      class="form-control border-0 font-size-14 bg-muted pl-2 mb-1"
      [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
    />
    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
      <div class="alert-danger" *ngIf="f.username.errors.required">{{ 'login.page.required.field' | translate }}</div>
    </div>
  </div>

  <div class="form-group input-info font-weight-bold">
    <label class="field-title password-field mb-2 mt-0 mt-lg-0 font-weight-bold" for="password">{{
      'login.page.member.password' | translate
    }}</label>
    <input
      type="password"
      formControlName="password"
      placeholder="Enter here"
      class="form-control border-0 font-size-14 bg-muted pl-2 mb-1"
      [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
    />

    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
      <div class="alert-danger" *ngIf="f.password.errors.required">{{ 'login.page.required.field' | translate }}</div>
    </div>
  </div>
  <div class="d-flex align-items-center justify-content-center">
    <h5 class="font-size-12" [innerHTML]="'login.page.click.here.text' | translate | markdownTranslate"></h5>
  </div>
  <div class="form-group mb-2 mt-4 mt-lg-0">
    <button [disabled]="loading" class="btn btn-success btn-block login-button font-size-14 w-100 font-weight-bold">
      <span *ngIf="!loading">{{ 'login.page.login.field' | translate }}</span>
      <fgbcl-loading [size]="'small'" *ngIf="loading"></fgbcl-loading>
    </button>
  </div>
  <fgbcl-errors></fgbcl-errors>
</form>
