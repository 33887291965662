import { Component, OnInit, Input } from '@angular/core';
import { AnswerDisplayType, SurveyAnswer } from '@fgb/core';

@Component({
  selector: 'fgb-predictor-completed-pending',
  templateUrl: './predictor-completed-pending.component.html',
  styleUrls: ['./predictor-completed-pending.component.scss', '../../../../survey.scss']
})
export class PredictorCompletedPendingComponent implements OnInit {
  @Input() selectedAnswers: SurveyAnswer[];
  @Input() displayType: AnswerDisplayType;
  answerDisplayType = AnswerDisplayType;
  constructor() { }

  ngOnInit() {
  }

}
