import { Component, OnInit } from '@angular/core';
import { TicketQuery, Ticket, TicketService } from '@fgb/core';
import { Observable } from 'rxjs';
import { GameQuery } from '@fgb/core';
import { Game } from '@fgb/core';
import { Router } from '@angular/router';

@Component({
  selector: 'fgb-ticket-transfer-success-page',
  templateUrl: './ticket-transfer-success-page.component.html',
  styleUrls: ['./ticket-transfer-success-page.component.scss'],
})
export class TicketTransferSuccessPageComponent implements OnInit {
  transferedTickets$: Observable<Ticket[]>;
  selectedGame: Game;

  constructor(
    private ticketQuery: TicketQuery,
    private gameQuery: GameQuery,
    private ticketService: TicketService,
    private router: Router
  ) {}

  ngOnInit() {
    this.transferedTickets$ = this.ticketQuery.selectTransferredTickets();
    this.selectedGame = this.gameQuery.getActive() as Game;
  }

  goBack() {
    this.ticketService.resetSelectedTickets();
    this.router.navigateByUrl('/ticketTransfer/tickets/' + this.selectedGame.GameNumber);
  }
}
