<div class="container">
  <h2 class="font-weight-bold font-size-20">{{ 'card.link.offers.featured.title' | translate }}</h2>
  <fgbcl-card-linking-featured-offers [numberOfOffers]="3"> </fgbcl-card-linking-featured-offers>
</div>

<!--Featured Partners Section-->
<fgbcl-card-link-featured-brands [Title]="'card.linking.featured.brands.title'"></fgbcl-card-link-featured-brands>

<div class="container all-offers-steps">
  <fgbcl-card-link-earn-steps
    [cardTitle]="'card.link.easy.steps.title'"
    [stepOneIcon]="'ads_click'"
    [stepTwoIcon]="'shopping_cart'"
    [stepThreeIcon]="'emoji_events'"
    [stepOneText]="'payment.description.step.one'"
    [stepOneSmallPrintText]="'payment.description.step.one.small.print'"
    [stepTwoText]="'payment.description.step.two'"
    [stepTwoSmallPrintText]="'payment.description.step.two.small.print'"
    [stepThreeText]="'payment.description.step.three'"
    [stepThreeSmallPrintText]="'payment.description.step.three.small.print'"
    [horizontalLine]="false"
    [filled]="false"
    [border]="true"
  >
  </fgbcl-card-link-earn-steps>
</div>

<div class="container mb-3">
  <div class="affiliate-tool-bar p-3">
    <div class="tool-bar-items py-2">
      <h2 class="toolbar-title font-size-20 mt-0" *ifScreenSize="screenType.Mobile">
        {{ 'card.link.offers.toolbar.title' | translate }}
      </h2>
      <ng-container *ifScreenSize="screenType.Desktop">
        <fgbcl-affiliates-offers-category-filter
          [navButtons]="true"
          (categoryTagIdEmitter)="categorytagID = $event; getOffers()"
        ></fgbcl-affiliates-offers-category-filter>
      </ng-container>

      <fgbcl-shared-search-bar (searchTextEmitter)="searchText = $event; getOffers()"></fgbcl-shared-search-bar>
    </div>
  </div>
</div>

<div class="container" *ifScreenSize="screenType.Mobile">
  <h2 class="font-weight-bold font-size-20">{{ 'card.link.offers.categories.title' | translate }}</h2>
  <fgbcl-affiliates-offers-category-filter
    (categoryTagIdEmitter)="categorytagID = $event; getOffers()"
  ></fgbcl-affiliates-offers-category-filter>
</div>

<div class="container">
  <hr class="horizontal-line" *ifScreenSize="screenType.Mobile" />
  <fgbcl-card-link-offers-list
    [cardLinkOfferOptions]="cardLinkOfferOptions"
    [horizontalLine]="false"
  ></fgbcl-card-link-offers-list>
</div>

<ng-template #noOffers>
  <div class="text-center no-offer-block">
    <span class="icon material-icons noOffer-icon" aria-hidden="true"> manage_search </span>
    <p class="font-weight-bold font-size-20">{{ 'card.link.no.search.offer.text' | translate }}</p>
    <p>{{ 'card.link.no.search.offer.text.suggestion' | translate }}</p>
  </div>
</ng-template>

<ng-template #loadingRef>
  <fgbcl-loading></fgbcl-loading>
</ng-template>
