<fgbcl-game-banner></fgbcl-game-banner>
<div class="container">
  <p>
    Ticket successfully transferred to
    <span *ngSubscribe="transferedTickets$ as transferedTickets" class="email">
      {{ transferedTickets[0].RecipientEmailAddress }}</span
    >
  </p>
  <table class="table">
    <thead>
      <tr>
        <th scope="col">Ticket Number</th>
        <th scope="col">Area</th>
        <th scope="col">Section</th>
        <th scope="col">Row</th>
        <th scope="col">Seat</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of transferedTickets$ | async">
        <th scope="row">{{ item.ACN }}</th>
        <td>
          {{ item.Area }}
        </td>
        <td>{{ item.Stand }}</td>
        <td>{{ item.Row }}</td>
        <td>{{ item.Seat }}</td>
      </tr>
    </tbody>
  </table>

  <div class="form-group">
    <button class="btn btn-danger" type="button" (click)="goBack()">
      {{ 'ticket-transfer-success-page-back-button' | translate }}
    </button>
  </div>
</div>
