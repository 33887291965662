<div class="welcome-page">
    <div class="close-button" [routerLink]="backLink" *ngIf="showCloseIcon">
      <span class="icon icon-cancel-circle"></span>
    </div>
    <img src="{{ logo }}" alt="welcome-logo" />
    <div class="wrap-header">
      <div class="title">{{ 'welcome.page.title' | translate }}</div>
      <div class="sub-title">{{ 'welcome.page.sub.title' | translate }}</div>
    </div>
    <div class="content">
      <div class="attend-games">
        <div class="material-icons">{{ firstIcon }}</div>
        <div class="text">{{ 'welcome.page.rule.1' | translate }}</div>
      </div>
      <div class="rewards">
        <div class="material-icons">{{ secondIcon }}</div>
        <div class="text">{{ 'welcome.page.rule.2' | translate }}</div>
      </div>
      <div class="redeem">
        <div class="material-icons">{{ thirdIcon }}</div>
        <div class="text">{{ 'welcome.page.rule.3' | translate }}</div>
      </div>
    </div>
    <button class="btn btn-back">
      <a [routerLink]="'/'">{{ routerLink | translate }}</a>
    </button>
  </div>
  
