<div>
  <div class="d-flex flex-lg-row flex-md-row flex-column">
    <div class="club-bannar">
      <img src="assets/images/background.jpg"/>
    </div>
    <div>
      <div class="container p-5" >
        <h1>{{ 'ticket.invite.page.title' | translate }}</h1>
        <ng-container *ngIf="isValidTicket">
          <p>{{ 'ticket.invite.page.discription' | translate }}</p>
          <fgbcl-ticket-invite [ticketKey]="ticketKey"></fgbcl-ticket-invite
        ></ng-container>
        <ng-container *ngIf="!isValidTicket">
          <div class="text-danger">{{ errorCodeKey | translate }}</div>
       </ng-container>
      </div>
    </div>
  </div>
</div>
