<div class="banner" role="banner" *ifScreenSize="screenType.Desktop">
  <h1 class="text-white">{{ 'split.transactions.page.title' | translate }}</h1>
</div>
<ng-container *ifScreenSize="screenType.Mobile">
  <div class="container my-3">
    <fgb-barcode></fgb-barcode>
  </div>
</ng-container>
<fgb-account-nav></fgb-account-nav>

<div class="container">
  <fgb-split-transaction-history></fgb-split-transaction-history>
</div>
