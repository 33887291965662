<div class="card b-0 mt-3 mt-lg-0">
  <div class="card-body p-3">
    <div class="d-flex flex-column flex-lg-row justify-content-left">
      <div class="col-lg-7">
        <fgbcl-date-range-picker [(startDate)]="transactionStartDate" [(endDate)]="transactionEndDate"></fgbcl-date-range-picker>
      </div>
      <div class="col-lg-2"></div>
      <div class="col-lg-2">
        <button
          type="button"
          class="search-button font-weight-bold d-block border-0 text-white bg-primary"
          (click)="splitTransaction.reset(); splitTransaction.search()"
        >
          {{ 'search.transaction' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="mt-4">
  <fgb-split-transaction-list
    [dateFrom]="transactionStartDate"
    [dateTo]="transactionEndDate"
    #splitTransaction
  ></fgb-split-transaction-list>
</div>
