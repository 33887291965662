import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TicketService } from '@fgb/core';

@Component({
  selector: 'fgb-ticket-invite-page-component',
  templateUrl: './ticket-invite-page-component.component.html',
  styleUrls: ['./ticket-invite-page-component.component.scss'],
})
export class TicketInvitePageComponentComponent implements OnInit {
  isValidTicket: boolean = true;
  ticketKey: string = '';
  errorCodeKey: string;
  constructor(private ticketService: TicketService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.ticketKey = this.getQueryString('ticketId', '');
    this.ticketService.validateTicketInviteKey(this.ticketKey).subscribe({
      next: () => {
        this.isValidTicket = true;
        this.errorCodeKey = '';
      },
      error: (error: HttpErrorResponse) => {
        this.isValidTicket = false;
        if (error.status == 400) {
          this.errorCodeKey = String(error.error.Code).toLowerCase();
        }
      },
    });
  }

  /* will get the ticket key form query string from url*/
  getQueryString(parameter: string, defaultValue: string): string {
    let matchedQueryStringParameter = this.route.snapshot.queryParamMap.keys.find(
      (param) => param.toUpperCase() === parameter.toUpperCase()
    );
    if (matchedQueryStringParameter === undefined) {
      return defaultValue;
    }
    return this.route.snapshot.queryParamMap.get(matchedQueryStringParameter) || defaultValue;
  }
}
