<div class="banner" role="banner" *ifScreenSize="screenType.Desktop">
  <h1 class="text-white">{{ name$ | async }}</h1>
</div>

<ng-container *ifScreenSize="screenType.Mobile">
  <div class="container my-3">
    <fgb-barcode></fgb-barcode>
  </div>
</ng-container>

<fgb-account-nav></fgb-account-nav>

<div class="container">
  <div class="row">
    <div class="col-12 col-lg-6 order-lg-1 order-1">
      <ng-container *ifScreenSize="screenType.Desktop">
        <h5 class="text-left mb-3 mt-lg-0">{{ 'account.member.card.title' | translate }}</h5>

        <fgb-member-card [showSeating]="true"></fgb-member-card>
      </ng-container>
    </div>

    <div class="col-12 col-lg-6 order-lg-2 order-3">
      <h5 class="text-left my-3 mt-lg-0">{{ 'account.details.title' | translate }}</h5>
      <fgb-account-details></fgb-account-details>
      <div class="mt-3"></div>
    </div>
  </div>
</div>
