<div class="banner" role="banner" *ifScreenSize="screenType.Desktop">
  <h1 class="text-white">{{ 'home.page.title' | translate }}</h1>
</div>

<!-- Default Population || Partner Population -->
<div *inPopulation="[populations.Default, populations.Partner]">
  <ng-container *ifScreenSize="screenType.Mobile">
    <div class="container my-3">
      <fgb-barcode></fgb-barcode>
    </div>
  </ng-container>

  <div class="container" *ifScreenSize="screenType.Desktop">
    <div class="row mt-4">
      <div class="col-lg-6">
        <fgb-member-card></fgb-member-card>
      </div>
      <div class="col-lg-6">
        <img src="assets/images/homepagecard.png" class="card border rounded w-100 h-100 m-auto" />
      </div>
    </div>
  </div>

  <div>
    <!-- Featured Rewards -->
    <div class="container">
      <fgbcl-featured-rewards *inPopulation="[populations.Default]" [showAllPath]="'rewards/events'"></fgbcl-featured-rewards>
    </div>

    <!-- Campaign Offers -->
    <ng-container *inPopulation="[populations.Default]">
      <div class="offers-container bg-primary py-2 mb-3 mt-3" [class.d-none]="campaignOffersList.length === 0">
        <fgbcl-campaigns-offers-list (dataChange)="onCampaignOfferListChange($event)"></fgbcl-campaigns-offers-list>
      </div>
    </ng-container>

    <!-- Announcements -->
    <div class="container">
      <fgbcl-announcements *inPopulation="[populations.Default, populations.Partner]"></fgbcl-announcements>

      <fgb-vouchers *inPopulation="[populations.Default]"></fgb-vouchers>
    </div>
  </div>
</div>
<!-- Nonrenewing Population -->
<div *inPopulation="[populations.NonRenewingPopulation]">
  <ng-container *ifScreenSize="screenType.Mobile">
    <div class="card text-center mt-3 ml-3 mr-3">
      <div class="row mt-2 pl-3 pr-3">
        <div class="col-lg-6 pl-0 pr-0">
          <div *ngIf="memberDetails$ | async as memberDetails">
            <div class="mb-0 card-name text-primary text-uppercase">
              {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
            </div>
            <ng-container *ngIf="memberCard$ | async as memberCard">
              <div class="text-primary font-size-12 mb-2">{{ memberCard.ExternalRefNumber }}</div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="container text-center mt-3">
      <div class="row mt-3 pl-3 pr-3">
        <div class="col-lg-6 pl-0 pr-0">
          <img src="assets/images/login-registration/minnesotacover.png" class="w-100 h-100 m-auto border-style-dkt" />
        </div>
        <div class="border-style-dkb col-lg-6 pl-0 border">
          <p class="renewing text-secondary text-left font-size-18 justify-content-left mt-3 pl-3 mb-2">
            {{ 'home.page.renewing.heading' | translate }}
          </p>
          <p
            class="renewing text-primary font-size-12 text-bold text-left mt-3 justify-content-left pl-3"
            [innerHTML]="'home.page.renewing.label' | translate | markdownTranslate"
          ></p>
        </div>
      </div>
      <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.partners'">
        <div class="col-12 mt-3">
          <h5 class="text-left mb-1 mt-lg-5">{{ 'partner.accept' | translate }}</h5>
          <div class="mb-2 mt-2 mt-lg-0">
            <div class="row m-0 partner-card">
              <p class="m-0 text-left font-size-14">
                {{ 'partner.text.message' | translate }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 mt-3 mb-3">
          <button
            class="
              accept-partner-button
              border-0
              text-center text-white
              bg-green
              pt-0
              d-flex
              align-items-center
              justify-content-center
            "
            (click)="openAcceptForm()"
            [ngClass]="{ active: !showAddForm, 'not-active': showAddForm, 'greyed-out': currentPartnerCount >= partnerLimit }"
            aria-label="Accept partner"
          >
            <span class="material-icons mr-2"> task_alt </span>
            <label class="cursor-pointer mb-0"> {{ 'nav.accept.partner' | translate: { fgbdefault: 'Accept Partner' } }}</label>
          </button>

          <hr class="my-1 bg-white w-100 mt-3 mb-4" />
        </div>
      </ng-container>
      <div class="col-12 mt-3">
        <button
          class="renewing-partner-button border-0 text-center text-white bg-secondary"
          (click)="toggleAcceptForm()"
          [ngClass]="{ active: !showAddForm, 'not-active': showAddForm, 'greyed-out': currentPartnerCount >= partnerLimit }"
          aria-label="Log Out"
        >
          <span aria-hidden="true" class="icon material-icons material-mobile-dropdown">power_settings_new</span>
          <label class="cursor-pointer mb-0 ml-2 font-size-14">{{ 'nav.log.out' | translate: { fgbdefault: 'Log out' } }}</label>
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ifScreenSize="screenType.Desktop">
    <div class="container text-center mt-5">
      <div class="row mt-3 pl-3 pr-3">
        <div class="col-lg-6 pl-0 pr-0">
          <img src="assets/images/login-registration/minnesotacover.png" class="w-100 h-100 m-auto border-style-dkl" />
        </div>
        <div class="border-style-dkr col-lg-6 pl-0 border">
          <p class="renewing text-secondary text-left font-size-18 justify-content-left mt-3 pl-3 mb-2">
            {{ 'home.page.renewing.heading' | translate }}
          </p>
          <p
            class="renewing text-primary font-size-12 text-bold text-left mt-3 justify-content-left pl-3"
            [innerHTML]="'home.page.renewing.label' | translate | markdownTranslate"
          ></p>
        </div>
      </div>
    </div>
    <!-- Accept partner-->
    <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.partners'">
      <div class="container">
        <div class="row m-0 partner-card">
          <div class="col-12 mt-1">
            <h5 class="text-left mb-1 mt-lg-5">{{ 'partner.accept' | translate }}</h5>
            <div class="mb-2 mt-2 mt-lg-0">
              <div class="row m-0 partner-card">
                <p class="m-0 text-info font-size-14">
                  {{ 'partner.text.message' | translate }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 mt-3">
            <button
              class="
                dkt-accept-partner-button
                border-0
                text-center text-white
                bg-green
                pt-0
                d-flex
                align-items-center
                justify-content-center
              "
              (click)="openAcceptForm()"
              [ngClass]="{ active: !showAddForm, 'not-active': showAddForm, 'greyed-out': currentPartnerCount >= partnerLimit }"
              aria-label="Accept partner"
            >
              <span class="material-icons mr-2"> task_alt </span>
              <label class="text-bold font-size-14 cursor-pointer mb-0">{{ 'accept.partner' | translate }}</label>
            </button>
          </div>
          <hr class="my-1 bg-white w-100 mb-5" />
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
