import { Component, OnInit } from '@angular/core';
import { EntityNotificationGroup, LoyaltyActionType, NotificationManagementQuery } from '@fgb/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'fgb-notification-management',
  templateUrl: './notification-management.component.html',
  styleUrls: ['./notification-management.component.scss'],
})
export class NotificationManagementComponent implements OnInit {
  notificationGroups$: Observable<EntityNotificationGroup[]>;
  LoyaltyActionType = LoyaltyActionType;

  constructor(private notificationManagementQuery: NotificationManagementQuery) {}

  ngOnInit() {
    this.notificationGroups$ = this.notificationManagementQuery.selectAll();
  }

  trackGroup(index: number, group: EntityNotificationGroup) {
    return group ? group.NotificationGroupId : undefined;
  }
}
