import { Component, OnInit } from '@angular/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
@Component({
  selector: 'fgb-split-transaction-page',
  templateUrl: './split-transaction-page.component.html',
  styleUrls: ['./split-transaction-page.component.scss']
})
export class SplitTransactionPageComponent implements OnInit {
  screenType = ScreenType;
  constructor() { }

  ngOnInit(): void {
  }

}
