<div class="banner" role="banner" *ifScreenSize="screenType.Desktop">
  <h1 class="text-white">{{ 'ecash.page.title' | translate }}</h1>
</div>

<ng-container *ifScreenSize="screenType.Mobile">
  <div class="container my-3">
    <fgb-barcode></fgb-barcode>
  </div>
</ng-container>

<fgb-account-nav></fgb-account-nav>

<div class="container mt-4">
  <fgb-transaction-history
    [transactionTypes]="[transactionTypes.ecash, transactionTypes.payment]"
    [ecashView]="true"
  ></fgb-transaction-history>
</div>
