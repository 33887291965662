<div id="popupWindow" class="d-block">
  <div class="modal-header">
    <span>{{ popupData.popUpNotificationLanguage.name }}</span>
  </div>
  <div class="modal-body">
    <fgb-popup-info
      id="text-popup-body"
      *ngIf="isTextPopup()"
      [description]="popupData.popUpNotificationLanguage.description"
      [image]="popupData.image"
    ></fgb-popup-info>

    <div id="action-popup-body" *ngIf="isActionPopup()">
      <p class="my-2">{{ popupData.popUpNotificationLanguage.description }}</p>
      <ng-container [ngTemplateOutlet]="dynamicComponentRef"></ng-container>
    </div>
  </div>
  <div class="modal-footer" *ngIf="isTextPopup()">
    <fgb-popup-action-confirm
      id="action-confirm"
      *ngIf="isActionConfirm()"
      (popupResponse)="emitResponse($event)"
    ></fgb-popup-action-confirm>
    <fgb-popup-action-ok-cancel
      id="action-ok-cancel"
      *ngIf="isActionOKCancel()"
      (popupResponse)="emitResponse($event)"
    ></fgb-popup-action-ok-cancel>
  </div>
</div>
