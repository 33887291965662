import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'fgb-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  @Input() showCloseIcon: boolean = false;
  @Input() firstIcon: string = 'local_police';
  @Input() secondIcon: string = 'redeem';
  @Input() thirdIcon: string = 'qr_code_scanner';
  @Input() logo: string = 'assets/images/login-registration/registration-logo.svg';
  @Input() backLinkText: string = 'welcome.page.back.link.text';
  @Input() backLink: string = '/';
  @Input() paramInRedirectPage: string = 'isHomePage';
  routerLink: string = '';
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.isRedirectFormPage() ? this.getLinkText(true) : this.getLinkText(false);
  }

  getLinkText(boolean: boolean) {
    boolean && (this.routerLink = this.backLinkText);
    !boolean && (this.routerLink = 'welcome.page.let.started');
  }

  /** Exxample in page redirect:
   *  <a [routerLink]="'/how-it-works'" [queryParams]="{ paramInRedirectPage: true }">
   */
  isRedirectFormPage() {
    return this.route.snapshot.queryParamMap?.get(this.paramInRedirectPage);
  }

}
