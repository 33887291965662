<div class="full-page w-100 d-md-flex d-block px-0">
  <!--Left Side-->
  <img
    class="d-md-block d-none shared-login-banner align-items-center justify-content-center"
    draggable="false"
    src="assets/images/login-registration/login-banner-demo.png"
  />
  <div class="bg-primary d-flex w-100 d-md-none m-auto mobile-header"></div>
  <!--Right Side-->
  <div class="right-side-login">
    <!-- <h3 class="text-secondary mb-4">{{ 'login.page.title' | translate }}</h3> -->
    <div class="d-md-flex d-none align-items-center justify-content-center">
      <img alt="" class="card-logo" src="assets/images/Twins_logo2.svg" draggable="false" />
    </div>
    <div class="text-primary panel-text mlb-text font-size-14">
      {{ 'login.page.description' | translate }}
    </div>
    <fgb-mlbam-user-login></fgb-mlbam-user-login>

    <div class="container text-center">
      <!-- Cards -->
      <a [routerLink]="['/register/new-member']">
        <div class="d-flex justify-content-center align-items-center mt-1">
          <div><img src="assets/images/login-registration/MLB.svg" class="logo mt-1" /></div>
          <div class="pl-2 font-size-14 text-primary font-weight-bold">
            {{ 'login.page.create.account.description' | translate }}
          </div>
        </div>
      </a>
    </div>
    <a [routerLink]="['/register/partner']">
      <div class="btn btn-primary btn-block create-account-button w-100 font-size-14 font-weight-bold mt-5">
        <span>{{ 'login.page.create.account.button' | translate }}</span>
      </div>
    </a>
  </div>
</div>
