<ng-container *ngIf="transactions$ | async as transactions">
  <div *ngIf="transactions?.length > 0">
    <ng-container *ngFor="let data of transactions; trackBy: trackByFn">
      <h4 class="split-transaction-list-header">{{ data?.date }}</h4>
      <div class="split-transaction-list">
        <ng-container *ngFor="let item of data.transactions">
          <fgb-split-transaction-item [data]="item"></fgb-split-transaction-item>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="!(loading$ | async)">
      <div class="btn-container">
        <button id="load-more-btn" class="primary" (click)="loadMore()" *ngIf="!isLoadDisabled">Load More</button>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="loading$ | async">
    <div class="split-transaction-list-loading">
      <fgbcl-loading></fgbcl-loading>
    </div>
  </ng-container>

  <div *ngIf="transactions?.length == 0 && noSearchEnabled && !(loading$ | async)">
    <div class="d-flex flex-column">
      <span class="icon material-icons material-history-icon mx-auto text-grey mt-2 mb-3">history</span>
      <h5 class="text-center mb-0 text-uppercase font-size-18 text-grey">
        {{ 'account.transactions.placeholder.list.title' | translate }}
      </h5>
      <p class="text-center font-size-12 text-grey">{{ 'account.activity.placeholder.list.description' | translate }}</p>
    </div>
  </div>
</ng-container>
