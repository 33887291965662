<div class="banner" role="banner">
  <h1>{{ 'games.page.pick.player.title' | translate }}</h1>
</div>

<div class="container">
  <div class="pick-a-player-bar d-flex d-lg-none mt-2">
    <a
      [routerLink]="[backButtonLink]"
      class="text-primary flex-grow-0 flex-shrink-0 d-flex align-items-center rounded justify-content-center h-100 font-weight-bold"
    >
      <span class="material-icons"> chevron_left </span>
      <span class="font-weight-bold font-size-14">{{ 'pick.player.back.button' | translate }}</span>
    </a>
  </div>
  <div class="row my-3">
    <div class="col-lg-4 col-12">
      <fgb-fantasy-game-panel
        [game]="game$ | async"
        (button)="confirmSelection()"
        [buttonState]="button"
        [horizontal]="false"
      ></fgb-fantasy-game-panel>
    </div>
    <div class="col-lg-8 col-12">
      <div class="d-none d-lg-block">
        <div class="row">
          <div class="col-3" *ngFor="let player of players$ | async">
            <div class="card rounded overflow-hidden mb-3 border-0 shadow" (click)="select(player.PlayerId)">
              <img alt="Player Picture" class="card-img-top w-100" src="{{ player.Image | contentImage : '7' }}" />
              <div
                class="card-body d-flex justify-content-start align-items-center"
                [ngClass]="{ 'bg-primary text-white': player.IsSelected, 'text-black bg-white': !player.IsSelected }"
              >
                <div class="mx-auto mb-0 font-size-12 text-capitalize">{{ player.PlayerName }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-block d-lg-none container">
        <div class="row">
          <div class="col-12 card border-0 shadow rounded overflow-hidden my-2" *ngFor="let player of players$ | async">
            <div class="row" (click)="select(player.PlayerId)">
              <div
                class="col-2 pl-0 pr-0"
                [ngClass]="{ 'bg-primary text-white': player.IsSelected, 'text-black bg-white': !player.IsSelected }"
              >
                <img alt="Player Picture" class="w-100" src="{{ player.Image | contentImage : '7' }}" />
              </div>
              <div
                class="col-10 d-flex align-items-center"
                [ngClass]="{ 'bg-primary text-white': player.IsSelected, 'text-black bg-white': !player.IsSelected }"
              >
                <div class="ml-1 mb-0 font-size-12 text-capitalize">{{ player.PlayerName }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-none d-lg-block">
    <a [routerLink]="[backButtonLink]" class="btn d-flex align-items-center font-weight-bold px-0">
      <span class="material-icons"> chevron_left </span>
      <span class="font-weight-bold font-size-14">{{ 'pick.player.back.button' | translate }}</span>
    </a>
  </div>
</div>
