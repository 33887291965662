import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoggingService, NotificationCenterService, PortalProductsService, SignalRHub } from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fgb-master-page',
  templateUrl: './master-page.component.html',
  styleUrls: ['./master-page.component.scss'],
})
export class MasterPageComponent implements OnInit, OnDestroy {
  private _destroyed$ = new Subject();
  screenType = ScreenType;

  constructor(
    private signalRHub: SignalRHub,
    private logService: LoggingService,
    private notificationCenterService: NotificationCenterService,
    private portalProductsService: PortalProductsService
  ) {}

  ngOnInit() {
    this.signalRHub.connectionEstablished$.pipe(takeUntil(this._destroyed$)).subscribe((connection) => {
      this.logService.debug('SignalR connection', connection);
    });
    this.notificationCenterService.fetchNotificationCenter(5).pipe(takeUntil(this._destroyed$)).subscribe();
    this.portalProductsService.fetchPortalProducts().pipe(takeUntil(this._destroyed$)).subscribe();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
