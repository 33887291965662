import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountDetailsComponent } from './components/account-details/account-details.component';
import { AuthCallbackComponent } from './components/auth/auth-callback/auth-callback.component';
import { CardListItemComponent } from './components/cards/card-list-item/card-list-item.component';
import { MemberCardComponent } from './components/member-card/member-card.component';
import { SingleSignOnComponent } from './components/single-sign-on/single-sign-on.component';
import { UserLoginComponent } from './components/user-login/user-login.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { BarcodeComponent } from './components/barcode/barcode.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PointsSummaryComponent } from './components/points-summary/points-summary.component';
import { EcashBalanceComponent } from './components/ecash-balance/ecash-balance.component';
import { RouterModule } from '@angular/router';
import { PointsSummaryBarComponent } from './components/points-summary-bar/points-summary-bar.component';
import { MlbamUserLoginComponent } from './components/mlbam-user-login/mlbam-user-login.component';
import { TransactionListComponent } from './components/transaction-list/transaction-list.component';
import { ReferAFriendComponent } from './components/refer-a-friend/refer-a-friend.component';
import { TransactionHistoryComponent } from './components/transaction-history/transaction-history.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { CardListComponent } from './components/cards/card-list/card-list.component';
import { CardSelectComponent } from './components/cards/card-select/card-select.component';
import { AddressSelectorComponent } from './components/address-selector/address-selector.component';
import { PointsManagementPlaceholderComponent } from './components/points-management-placeholder/points-management-placeholder.component';
import { PointsTransferComponent } from './components/points-transfer/points-transfer.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { PartnerListComponent } from './components/partner-list/partner-list.component';
import { PartnerPendingListComponent } from './components/partner-pending-list/partner-pending-list.component';
import { PartnerAddFormComponent } from './components/partner-add-form/partner-add-form.component';
import { PartnerAcceptFormComponent } from './components/partner-accept-form/partner-accept-form.component';
import { AveragePointsComponent } from './components/average-points/average-points.component';
import { TmUserLoginComponent } from './components/tm-user-login/tm-user-login.component';
import { EditAccountDetailsComponent } from './components/edit-account-details/edit-account-details.component';
import { V1SingleSignOnComponent } from './components/v1-single-sign-on/v1-single-sign-on.component';
import { SportsAllianceUserLoginComponent } from './components/sports-alliance-user-login/sports-alliance-user-login.component';
import { GbgIdentityStatusComponent } from './components/gbg-identity-status/gbg-identity-status.component';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ErrorsModule } from '@fgb/portal-component-library/src/lib/shared/errors';
import { DateRangePickerModule } from '@fgb/portal-component-library/src/lib/shared/date-range-picker';
import { CashBackToggleModule } from '@fgb/portal-component-library/src/lib/ecash/cash-back-toggle';
import { LoadingModule } from '@fgb/portal-component-library/src/lib/shared/loading';
import { KeyCloakUserLoginComponent } from './components/key-cloak-user-login/key-cloak-user-login.component';
import { SeatGeekUserLoginComponent } from './components/seatgeek-user-login/seatgeek-user-login.component';
import { MobilePassModule } from '@fgb/portal-component-library/src/lib/passes/mobile-pass';
import { SplitTransactionModule } from './components/split-transaction/split-transaction.module';
import { UserResetPasswordComponent } from './components/user-reset-password/user-reset-password.component';
import { UserChangePasswordComponent } from './components/user-change-password/user-change-password.component';
import { InvalidTokenModalComponent } from './components/invalid-token-modal/invalid-token-modal.component';
import { PopupAddToHomeScreenComponent } from './components/popup-add-to-home-screen/popup-add-to-home-screen.component';

@NgModule({
  declarations: [
    AccountDetailsComponent,
    AuthCallbackComponent,
    CardListItemComponent,
    MemberCardComponent,
    SingleSignOnComponent,
    UserLoginComponent,
    BarcodeComponent,
    EcashBalanceComponent,
    PointsSummaryComponent,
    PointsSummaryBarComponent,
    MlbamUserLoginComponent,
    ReferAFriendComponent,
    TransactionListComponent,
    TransactionHistoryComponent,
    CardListComponent,
    CardSelectComponent,
    AddressSelectorComponent,
    PointsManagementPlaceholderComponent,
    PointsTransferComponent,
    PartnerListComponent,
    PartnerPendingListComponent,
    PartnerAddFormComponent,
    PartnerAcceptFormComponent,
    AveragePointsComponent,
    TmUserLoginComponent,
    EditAccountDetailsComponent,
    V1SingleSignOnComponent,
    SportsAllianceUserLoginComponent,
    KeyCloakUserLoginComponent,
    GbgIdentityStatusComponent,
    SeatGeekUserLoginComponent,
    UserResetPasswordComponent,
    UserChangePasswordComponent,
    InvalidTokenModalComponent,
    PopupAddToHomeScreenComponent,
  ],
  exports: [
    AccountDetailsComponent,
    AuthCallbackComponent,
    CardListItemComponent,
    MemberCardComponent,
    SingleSignOnComponent,
    UserLoginComponent,
    BarcodeComponent,
    EcashBalanceComponent,
    PointsSummaryComponent,
    PointsSummaryBarComponent,
    MlbamUserLoginComponent,
    TransactionHistoryComponent,
    ReferAFriendComponent,
    CardListComponent,
    CardSelectComponent,
    AddressSelectorComponent,
    PointsManagementPlaceholderComponent,
    PointsTransferComponent,
    PartnerListComponent,
    PartnerPendingListComponent,
    PartnerAddFormComponent,
    PartnerAcceptFormComponent,
    AveragePointsComponent,
    TmUserLoginComponent,
    EditAccountDetailsComponent,
    V1SingleSignOnComponent,
    SportsAllianceUserLoginComponent,
    KeyCloakUserLoginComponent,
    GbgIdentityStatusComponent,
    SeatGeekUserLoginComponent,
    SplitTransactionModule,
    PopupAddToHomeScreenComponent,
  ],
  imports: [
    NgbModule,
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    QRCodeModule,
    RouterModule,
    InlineSVGModule,
    CarouselModule,
    DateRangePickerModule,
    ErrorsModule,
    CashBackToggleModule,
    LoadingModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: function HttpLoaderFactory(http: HttpClient) {
          return new TranslateHttpLoader(http, './assets/i18n/', '.json');
        },
        deps: [HttpClient],
      },
    }),
    MobilePassModule,
    SplitTransactionModule,
  ],
})
export class UserModule {}
