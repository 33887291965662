<div class="split-transaction" [ngClass]="{ open: isOpen, disabled: isDisabled }">
  <div id="split-transaction-header" (click)="toggleBtn.click()">
    <button id="split-transaction-button" (click)="toggle(); $event.stopPropagation()" [disabled]="isDisabled" #toggleBtn>
      <span class="icon material-icons" [@rotateAnimation]="{ value: isOpen, params: { rotateEnd: '-90' } }">
        chevron_right
      </span>
    </button>
    <div class="header-wrapper">
      <h3 class="header-title">{{ data?.DescriptionText }}</h3>
      <span class="sub-header">{{ data?.TransactionDateUTC | UtcToLocaltimePipe: 'hh:mm a' | uppercase }}</span>
    </div>
    <div class="total-amnt" [ngStyle]="data?.Amount > 0 ? { color: '#52C443' } : { color: '#CC3525' }">
      {{ data?.Amount | FortressCurrencyPipe }}
    </div>
  </div>
  <div id="split-transaction-details" [@collapseAnimation]="isOpen">
    <div class="container">
      <div class="payment-details">
        <div class="discount">
          <span class="lbl">{{ 'split.transaction.discount.label' | translate: { fgbdefault: 'Discount' } }}</span>
          <span>{{ data?.DiscountAmount | FortressCurrencyPipe }} </span>
        </div>
      </div>

      <ng-container *ngIf="!(loading$ | async); else loading">
        <div class="item-details" *ngIf="itemDetails$ | async as itemDetails">
          <h5 class="text-bold">{{ 'item.details' | translate }}</h5>
          <div class="item-list">
            <div class="item-list-item" *ngFor="let item of itemDetails">
              <span class="item-desc">{{ item?.ProductDescription }}{{ item?.Quantity > 1 ? ' x ' + item?.Quantity : '' }}</span>
              <span class="total">{{ item?.TotalPrice | FortressCurrencyPipe }}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-template #loading>
    <div class="split-transaction-loading">
      <fgbcl-loading></fgbcl-loading>
    </div>
  </ng-template>
</div>
