<ng-container *ngIf="transactions$ | async as transactions">
  <ng-container *ngIf="transactions$ | async as transactions">
    <div *ngFor="let day of transactions | keyvalue: keepOrder">
      <h5 class="transaction-date mt-4 mb-3">{{ day.key }}</h5>
      <div class="mb-0 mb-lg-5">
        <div class="card my-3 py-2 px-4 border" *ngFor="let transaction of day.value">
          <div class="row">
            <div class="col-8 text-left">
              <div class="font-weight-bold text-black transaction-text">
                {{ transaction.TransactionDescription || transaction.DescriptionText }}
              </div>

              <div class="transaction-subtext font-weight-bold">
                {{ transaction.TransactionDateUTC | UtcToLocaltimePipe: 'hh:mm a' | uppercase }}
              </div>
            </div>
            <div class="col-4 text-right pt-2">
              <div
                class="font-weight-bold font-size-12 text-capitalize"
                [ngClass]="transaction.Amount >= 0 ? 'text-success' : 'text-secondary'"
              >
                {{ transaction.Amount >= 0 ? '+' : ''
                }}{{ transaction.Amount | FortressCurrencyPipe: (transactionType === transactionTypes.loyalty ? 'PTS' : 'USD') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="(!transactions || !hasTransactions) && transactionType === transactionTypes.loyalty">
      <div>
        <span class="icon material-icons material-history-icon text-grey no-activity-image text-center w-100 mt-5 mb-2"
          >history</span
        >
        <h5 class="transaction-history-title mb-1">
          {{ 'no.activity.placeholder.list.title' | translate }}
        </h5>
        <p class="text-info text-center font-size-14 font-weight-bold">
          {{ 'account.activity.placeholder.list.description' | translate }}
        </p>
      </div>
    </ng-container>
    <ng-container *ngIf="(!transactions || !hasTransactions) && transactionType === transactionTypes.ecash">
      <div>
        <span class="icon material-icons material-history-icon mx-auto text-grey no-activity-image text-center w-100 mt-5 mb-2"
          >history</span
        >
        <h5 class="transaction-history-title mb-1">
          {{ 'account.transactions.placeholder.list.title' | translate }}
        </h5>
        <p class="text-info text-center font-size-14 font-weight-bold">
          {{ 'account.activity.placeholder.list.description' | translate }}
        </p>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<div *ngIf="loading" class="mt-4">
  <fgbcl-loading></fgbcl-loading>
</div>
