<div class="banner" role="banner">
  <h1 class="text-white" *ifScreenSize="screenType.Desktop">{{ 'terms.page.title' | translate }}</h1>
  <h1 class="text-white mob-banner" *ifScreenSize="screenType.Mobile">{{ 'terms.page.title' | translate }}</h1>
</div>
<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="pt-4">
        <h1 class="terms-section-title text-center text-primary font-weight-bold mb-3">
          {{ 'terms.page.section.title' | translate }}
        </h1>
        <fgbcl-terms-and-conditions [type]="termsAndConditionsType" [defaultTerms]="defaultTemplate"></fgbcl-terms-and-conditions>
      </div>
    </div>
  </div>
</div>

<ng-template #defaultTemplate>
  <p class="terms-section-content text-left mb-3">Loading...</p>
</ng-template>
