import { Component, OnInit } from '@angular/core';
import { config, MemberDetails, MemberQuery, BalanceQuery } from '@fgb/core';
import { TransactionType } from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { Observable } from 'rxjs';

@Component({
  selector: 'fgb-ecash-history',
  templateUrl: './ecash-history.component.html',
  styleUrls: ['./ecash-history.component.scss'],
})
export class EcashHistoryPageComponent implements OnInit {
  transactionStartDate: Date;
  transactionEndDate: Date;
  transactionType: TransactionType;
  transactionTypes = TransactionType;
  screenType = ScreenType;
  memberDetails$: Observable<MemberDetails | undefined>;
  combinedCard$: Observable<number | undefined>;
  loyaltyPointsCard$: Observable<number | undefined>;

  constructor(private memberQuery: MemberQuery, private balanceQuery: BalanceQuery) {}
  memberId: string;

  ngOnInit() {
    this.memberId = this.memberQuery.getUserContext().memberId;
    this.memberDetails$ = this.memberQuery.selectMemberDetails();
    this.combinedCard$ = this.balanceQuery.selectPurse(config.purseConfig.combined);
    this.loyaltyPointsCard$ = this.balanceQuery.selectPurse(config.purseConfig.virtualLoyalty);
    this.transactionType = TransactionType.ecash;
    this.transactionEndDate = new Date();
    this.transactionStartDate = new Date();
    this.transactionStartDate.setDate(new Date(this.transactionStartDate).getDate() - 1);
  }
}
