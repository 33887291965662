<div *ngIf="image" class="flex-grow-0 flex-shrink-0">
  <img 
    src="{{ image | contentImage: '1' }}"
    alt="{{ image }}"
    class="image"
  />
</div>

<div class="d-flex flex-column justify-content-between flex-grow-1 flex-shrink-1">
  <div class="description">
    <div>{{description}}</div>
  </div>
</div>
