<div class="survey-container rounded bg-poll">
  <div class="survey-header text-center">
    <div class="image-container-polls w-100 overflow-hidden position-relative bg-white rounded-top d-flex" *ngIf="survey?.ImageURL else noImage">
      <img class="w-100" src="{{ survey.ImageURL | contentImage: '7' }}" alt="{{ survey.ImageURL }}" />
    </div>
    <ng-template #noImage>
      <div class="no-image text-white">
        <div class="no-image-title">{{ 'survey.item.poll.title' | translate }}</div>
        <div class="no-image-text">{{ 'survey.item.poll.text' | translate }}</div>
      </div>
    </ng-template>
    <div class="survey-info">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <div class="gIcon-polls material-icons inline-block my-0 text-white" aria-hidden="true">assessment</div>
        </div>
        <div class="px-3 d-none d-lg-block text-white survey-question">{{ survey.SurveyQuestion }}</div>

        <div
          class="text-center font-weight-bold text-tertiary font-size-16"
          [ngClass]="pointsWon > 0 ? 'd-block d-lg-none' : 'd-none'"
        >
          +{{ pointsWon }} Pts
        </div>
        <div class="text-right text-white" [ngClass]="{ countdown: survey.HasBeenAnsweredByUser }">
          <span class="icon icon-clock pr-1"></span>
          <fgbcl-countdown
            [endDateTime]="survey.EndDate"
            [requestDateTime]="survey.RequestTime"
            [isUTC]="false"
            class="font-size-16 font-weight-bold"
          ></fgbcl-countdown>
        </div>
      </div>
      <div class="px-3 d-block d-lg-none mt-3 text-white font-size-14 survey-question xs">{{ survey.SurveyQuestion }}</div>
    </div>
  </div>
  <ng-container *ngIf="survey.HasBeenAnsweredByUser; else answer">
    <fgb-survey-item-answer-completed-poll [survey]="survey" [pointsWon]="pointsWon"></fgb-survey-item-answer-completed-poll>
  </ng-container>
  <ng-template #answer>
    <fgb-survey-item-answer [survey]="survey"></fgb-survey-item-answer>
  </ng-template>
</div>
