<div class="card rounded border">
  <ng-container *ngIf="member$ | async as member">
    <div>
      <label class="mb-0">{{ 'account.details.dob' | translate }}</label>
      <div class="d-flex bg-muted p-2 m-0 mt-1 rounded">
        <span class="icon material-icons" aria-hidden="true">date_range</span>
        <span class="ml-3 text-info">{{ member.DOB | date: 'MMM d' }}</span>
      </div>
    </div>
    <div>
      <label class="mb-0 mt-3 personal-info">{{ 'account.details.email' | translate }}</label>
      <div class="d-flex bg-muted p-2 m-0 mt-1 rounded">
        <span class="icon material-icons" aria-hidden="true">email</span>
        <span class="ml-3 text-info">{{ member.EmailAddress1 }}</span>
      </div>
    </div>
  </ng-container>
</div>
<ng-template #loadingRef>
  <fgbcl-loading></fgbcl-loading>
</ng-template>
