<ng-container *ngIf="memberCard$ | async as memberCard; else loadingRef">
  <h2 class="accessibility-only">Member Card</h2>
  <div
    *ngIf="memberCard.RetailDiscountLevel != null || memberCard.RetailDiscountLevel != undefined; else default"
    class="member-card"
    [ngClass]="{
      'bg-secondary text-white': memberCard.RetailDiscountLevel.includes(15),
      'bg-gold text-black': memberCard.RetailDiscountLevel.includes(40),
      'bg-grey text-black': memberCard.RetailDiscountLevel.includes(10),
      'bg-primary text-white': memberCard.RetailDiscountLevel.includes(20)
    }"
  >
    <div class="d-flex flex-column justify-content-between h-100">
      <div class="d-flex justify-content-between mt-3">
        <!--Name and ID-->
        <div class="card-id text-left">
          <p class="mb-0 card-name text-uppercase" *ngIf="memberDetails$ | async as memberDetails">
            {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
          </p>
          <div class="d-flex font-size-12">
            <div>{{ memberCard.ProductCode }}</div>
            <div class="px-1">-</div>
            <div>{{ memberCard.ExternalRefNumber }}</div>
          </div>
        </div>
        <!--Logo-->
        <img
          alt=""
          *ngIf="memberCard.RetailDiscountLevel.includes(10)"
          class="card-logo"
          src="assets/images/Twins_logo2.svg"
          draggable="false"
        />
        <img
          alt=""
          *ngIf="memberCard.RetailDiscountLevel.includes(20)"
          class="card-logo"
          src="assets/images/Twins_logo.svg"
          draggable="false"
        />
        <img
          alt=""
          *ngIf="memberCard.RetailDiscountLevel.includes(15) || memberCard.RetailDiscountLevel.includes(40)"
          class="card-logo"
          src="assets/images/Twins_logo3.svg"
          draggable="false"
        />
      </div>

      <!--Balances-->
      <div>
        <div class="d-flex align-items-end mb-3">
          <div
            class="card-account-no text-left pr-3"
            [ngClass]="{
              'text-white border-right':
                memberCard.RetailDiscountLevel.includes(15) || memberCard.RetailDiscountLevel.includes(20),
              'border-right-black': memberCard.RetailDiscountLevel.includes(40) || memberCard.RetailDiscountLevel.includes(10)
            }"
          >
            <label class="font-size-14 mb-1">{{ memberCard.RetailDiscountLevel | getNumber }}%</label>
            <div class="font-size-12">{{ 'member.card.discount' | translate }}</div>
          </div>
          <div class="card-id text-left pl-3" *ngIf="combinedCard$">
            <label class="font-size-14 mb-1">{{ combinedCard$ | async | FortressCurrencyPipe }}</label>
            <div class="font-size-12 mb-0">{{ 'member.card.ecash.balance' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #default>
  <div class="member-card text-white bg-primary">
    <div class="d-flex flex-column justify-content-between h-100">
      <div class="d-flex justify-content-between mt-3">
        <!--Name and ID-->
        <div class="card-id text-left">
          <p class="mb-0 card-name text-uppercase" *ngIf="memberDetails$ | async as memberDetails">
            {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
          </p>
          <div class="d-flex font-size-12">
            <div>{{ memberCard.ProductCode }}</div>
            <div class="px-1">-</div>
            <div>{{ memberCard.ExternalRefNumber }}</div>
          </div>
        </div>
        <!--Logo-->
        <img alt="" class="card-logo" src="assets/images/Twins_logo.svg" draggable="false" />
      </div>
    </div>
  </div>
</ng-template>
<ng-template #loadingRef>
  <fgbcl-loading></fgbcl-loading>
</ng-template>
