<div class="survey-container overflow-hidden rounded predictor row">
  <div class="survey-header mb-1 col-12 col-lg-6">
    <div class="image-container">
      <img *ngIf="survey.ImageURL" src="{{ survey.ImageURL | contentImage : '23' }}" alt="{{ survey.ImageURL }}" />
      <div *ngIf="!survey.ImageURL" class="h-100 d-flex flex-column justify-content-center align-items-center py-4">
        <div class="text-primary predictor-placeholder-title">
          {{ 'predictor.noimage.placeholder.title' | translate }}
        </div>
        <div class="predictor-placeholder-description font-size-12">
          {{ 'predictor.noimage.placeholder.description' | translate }}
        </div>
      </div>
    </div>
    <div class="survey-info d-flex flex-column align-content-between text-white shadow bg-predictor">
      <div class="d-flex justify-content-between mb-4" *ngIf="!survey.HasBeenAnsweredByUser">
        <div class="d-flex align-items-center">
          <div class="gIcon-predictor material-icons" aria-hidden="true">flaky</div>
        </div>
        <div class="text-right">
          <span class="icon icon-clock pr-1"></span>
          <fgbcl-countdown
            [endDateTime]="survey.EndDate"
            [requestDateTime]="survey.RequestTime"
            [isUTC]="false"
            class="font-size-16 font-weight-bold"
          ></fgbcl-countdown>
        </div>
      </div>
      <div class="survey-title mb-3 font-weight-bold">
        <div>{{ survey.SurveyQuestion }}</div>
      </div>
      <div class="d-flex align-items-end mt-4 h-100">
        <button
          *ngIf="!survey.HasBeenAnsweredByUser"
          (click)="submitAnswer()"
          class="btn btn-success col-6 font-weight-bold mx-auto"
          [disabled]="disableSubmitButton"
        >
          Submit
        </button>
        <div class="d-flex flex-row w-100 justify-content-center my-1" *ngIf="survey.HasBeenAnsweredByUser">
          <div class="gIcon-predictor material-icons" aria-hidden="true">flaky</div>
          <div class="text-uppercase font-italic font-weight-bold font-size-25 px-2 pt-1">Entered</div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-lg-6">
    <ng-container *ngIf="survey.HasBeenAnsweredByUser; else answer">
      <fgb-survey-item-answer-completed-predictor [survey]="survey"></fgb-survey-item-answer-completed-predictor>
    </ng-container>
    <ng-template #answer>
      <div class="h-100 w-100 d-table">
        <div class="d-table-cell align-middle">
          <fgb-survey-item-answer #itemAnswer [survey]="survey" (predictorAnswers)="hasUserAnswered()"></fgb-survey-item-answer>
        </div>
      </div>
    </ng-template>
  </div>
</div>
