<div class="banner d-lg-flex d-none">
  <h1>{{ 'marketplace.page.title' | translate }}</h1>
</div>

<div class="container my-3 d-lg-none">
  <fgb-member-card></fgb-member-card>
</div>

<fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>

<div class="container mt-3">
  <fgbcl-marketplace-list
    [isList]="true"
    [marketplaceItemView]="true"
    [marketplaceItemTypes]="[0, 1, 2]"
    [defaultSort]="0"
    [remainingStockOnly]="false"
  ></fgbcl-marketplace-list>
</div>
