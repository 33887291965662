import { Component, OnInit, Input } from '@angular/core';
import { config, ChildViewModel, AuthService, MembershipOptionQuery, MembershipOption, BalanceQuery } from '@fgb/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ChildBarcodesCarouselComponent } from '../child-barcodes-carousel/child-barcodes-carousel.component';
import { LostBarcodeModalComponent } from 'src/app/shared/components/lost-barcode-modal/lost-barcode-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'fgb-child-panel',
  templateUrl: 'child-panel.component.html',
  styleUrls: ['child-panel.component.scss'],
})
export class ChildPanelComponent implements OnInit {
  @Input() child: ChildViewModel;
  @Input() enableAttachLostBarcodeButton: boolean = true;
  @Input() enableLoginButton: boolean = true;
  membershipOption: MembershipOption[];
  loyaltyPointsCard$: Observable<number | undefined>;

  constructor(
    private authService: AuthService,
    private membershipOptionQuery: MembershipOptionQuery,
    private balanceQuery: BalanceQuery,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit() {
    this.loyaltyPointsCard$ = this.balanceQuery.selectPurse(config.purseConfig.virtualLoyalty);
    this.membershipOptionQuery.selectAll().subscribe((options) => {
      this.membershipOption = options as MembershipOption[];
    });
  }

  loginToChild() {
    this.authService.switchUser(this.child.MemberId, false)
    .toPromise()
    .then(() => this.router.navigate(['/']))
    .then(() => location.reload());
  }

  openChildBarcode() {
    const modalRef = this.modalService.open(ChildBarcodesCarouselComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'children-barcodes',
    });
    modalRef.componentInstance.childId = this.child.MemberId.toString();
  }

  /**
   * Will open dialog to create and update barcode
   * @param isBarcodeLost flag to check if the
   * barcode update or not.
   */
  linkNewBarcode(isBarcodeLost: boolean = false) {
    const modalRef = this.modalService.open(LostBarcodeModalComponent, { centered: true, windowClass: 'link-barcode-modal' });
    modalRef.componentInstance.childId = this.child.Id;
    if (isBarcodeLost) {
      modalRef.componentInstance.isBarcodeLost = true;
    }
  }
}
