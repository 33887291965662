import { Component, OnInit } from '@angular/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';

@Component({
  selector: 'fgb-account-nav',
  templateUrl: './account-nav.component.html',
  styleUrls: ['./account-nav.component.scss'],
})
export class AccountNavComponent implements OnInit {
  screenType = ScreenType;
  constructor() {}

  ngOnInit() {}
}
