import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fgb-ticket-transfer-page',
  templateUrl: './ticket-transfer-page.component.html',
  styleUrls: ['./ticket-transfer-page.component.scss'],
})
export class TicketTransferPageComponent implements OnInit {
  showPastGamesSection: boolean = true;

  constructor() {}

  ngOnInit() {}

  shouldShowPastGameSection(count: number) {
    if (count <= 0) {
      this.showPastGamesSection = false;
    }
  }
}
