<div class="points-summary-bar d-flex justify-content-between d-lg-none my-2">
  <!-- Back Button -->

  <a class="cursor-pointer d-flex align-items-center d-lg-none" [routerLink]="[backButtonLink]">
    <span class="icon material-icons font-weight-bold pr-2 text-secondary">chevron_left</span>
    <span *ngIf="marketplaceItemGroup" class="font-size-14 text-secondary font-weight-bold"
      >Back to {{ marketplaceItemGroup }}</span
    >
    <span *ngIf="!marketplaceItemGroup" class="font-size-14 font-weight-bold">Back to {{ backButtonTitle }}</span>
  </a>
</div>
