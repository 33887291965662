import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MemberService } from '@fgb/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fgb-user-change-password',
  templateUrl: './user-change-password.component.html',
  styleUrls: ['./user-change-password.component.scss'],
})
export class UserChangePasswordComponent implements OnInit {
  formChangePassword: FormGroup;
  resetKey: string = '';
  contentModal: string;
  visiblePassword: boolean = false;
  visibleConfirm: boolean = false;
  @ViewChild('alert') alert: TemplateRef<any>;
  constructor(
    private _fb: FormBuilder,
    private memberService: MemberService,
    private route: ActivatedRoute,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.resetKey = this.getResetKeyFromRoute();
  }

  getResetKeyFromRoute() {
    return this.route.snapshot.queryParamMap.get('resetkey') || '';
  }

  initForm() {
    this.formChangePassword = this._fb.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    });
  }

  get isPasswordSameConfirm() {
    let password = this.formChangePassword.get('password')?.value;
    let confirm = this.formChangePassword.get('confirmPassword')?.value;
    return password === confirm;
  }

  submit() {
    if (this.formChangePassword.invalid) return;
    let password = this.formChangePassword.get('password')?.value;
    let confirm = this.formChangePassword.get('confirmPassword')?.value;
    this.memberService
      .changePassword(this.resetKey, password, confirm)
      .then((_) => this.openModal('Change password successful!'))
      .catch((err) => {
        const error = err.error.Message ? err.error.Message : 'System error, please try again later';
        this.openModal(error);
      });
  }

  openModal(message: string) {
    this.modalService.open(this.alert);
    this.contentModal = message;
  }

  toggleVisiblePassword() {
    this.visiblePassword = !this.visiblePassword;
  }

  toggleVisibleConfirm() {
    this.visibleConfirm = !this.visibleConfirm;
  }

  get checkForm() {
    return Object.keys(this.formChangePassword.controls).reduce((object: any, key: string) => {
      const formControl = this.formChangePassword.get(key);
      object[key] = formControl?.errors && (formControl?.dirty || formControl?.touched);
      return object;
    }, {});
  }
}
