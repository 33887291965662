<div class="full-page w-100 d-md-flex d-block px-0">
  <!--Left Side-->
  <img
    class="shared-login-banner d-md-block d-none"
    draggable="false"
    src="assets/images/login-registration/login-banner-demo.png"
  />
  <div class="bg-primary d-flex w-100 d-md-none m-auto mobile-header"></div>
  <!--Right Side-->
  <div class="right-side-login d-flex flex-column align-items-center justify-content-center">
    <div class="mb-2">
      <div class="icon material-icons maintenance-page-icon text-secondary" aria-hidden="true">engineering</div>
    </div>
    <div class="text-center">
      <h5
        class="maintenance-page-heading"
        [innerHTML]="'maintenancepage.currentlyunavailable.text' | translate | markdownTranslate"
      ></h5>
      <p class="font-size-14" [innerHTML]="'maintenancepage.currentlyunavailable.subheading' | translate | markdownTranslate"></p>
    </div>
  </div>
</div>
