<nav class="navbar bg-primary navbar-dark navbar-expand-lg d-none d-lg-flex shadow">
  <a
    class="navbar-brand py-0"
    [routerLink]="['/']"
    routerLinkActive="router-link-active"
    attr.aria-label="{{ 'nav.home.page.screenreader' | translate: { fgbdefault: 'Move to home page' } }}"
  >
    <label id="homeLink" class="accessibility-only">Go To Home Page</label>
    <img alt="" class="ml-1 h-100" aria-labelledby="homeLink" src="assets/images/Twins_logo.svg" draggable="false" />
  </a>

  <button
    class="navbar-toggler"
    type="button"
    (click)="isCollapsed = !isCollapsed"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    attr.aria-label="{{ 'nav.toggle.screenreader' | translate }}"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <!-- Left Side Content -->
  <div class="navbar-collapse" [ngbCollapse]="isCollapsed" id="navbarSupportedContent">
    <div class="nav-container">
      <ul class="navbar-nav mr-auto" #greedyNav>
        <li class="nav-item active" *inPopulation="[populations.Default]">
          <a
            class="nav-link mx-1 text-white"
            [routerLink]="['/rewards/events']"
            routerLinkActive="router-link-active"
            attr.aria-label="{{ 'nav.rewards.screenreader' | translate: { fgbdefault: 'Move to rewards page' } }}"
            >{{ 'nav.rewards' | translate }}</a
          >
        </li>

        <!--More-->
        <li class="nav-item active more-dropdown" *inPopulation="[populations.Default, populations.Partner]">
          <div ngbDropdown role="group">
            <a class="nav-link mx-1 text-white" ngbDropdownToggle>
              <span class="mr-1">{{ 'nav.help' | translate }}</span>
            </a>
            <div ngbDropdownMenu class="py-2 mt-2 mr-4 shadow w-100 border-0 rounded-0 help-dropdown" #greedyDropdown>
              <a
                ngbDropdownItem
                [routerLink]="['/faq']"
                class="pl-2 mb-1 d-block font font-weight-normal font-size-14 more-links"
              >
                {{ 'nav.faq' | translate }}
              </a>
              <a ngbDropdownItem [routerLink]="['/contact']" class="pl-2 mb-1 d-block font-weight-normal font-size-14 more-links">
                {{ 'nav.contact.us' | translate }}
              </a>
              <a ngbDropdownItem [routerLink]="['/terms']" class="pl-2 mb-1 d-block font-weight-normal font-size-14 more-links">
                {{ 'nav.terms' | translate }}
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <!-- Right Side Content -->
    <div class="form-inline my-2 my-lg-0">
      <!--eCash-->
      <ng-container *inPopulation="[populations.Default, populations.Partner]">
        <div *ngIf="combinedCard$" class="d-flex align-items-center pr-4 text-white border-right">
          <span class="icon material-icons font-size-20">monetization_on</span>
          <span class="pl-1 font-size-14 font-weight-bold"> {{ combinedCard$ | async | FortressCurrencyPipe }}</span>
        </div>
      </ng-container>

      <!--Notifications-->
      <ng-container *inPopulation="[populations.Default]">
        <fgb-notification-button></fgb-notification-button>
      </ng-container>

      <!--New Dropdown (Needs Manage Tickets update before being looked at again)-->
      <div class="member" ngbDropdown #ddAccount="ngbDropdown" [autoClose]="'outside'" focusTab>
        <button
          class="nav-link btn d-flex text-white align-items-center mx-2 px-0 justify-content-end"
          id="namedropdown"
          ngbDropdownToggle
          attr.aria-label="{{ 'nav.account.dropdown.screenreader' | translate: { fgbdefault: 'Click for more account menu' } }}"
          tabindex="-1"
        >
          <div *ngIf="memberDetails$ | async as memberDetails; else memberDetailsLoading" class="user-box h-100 d-inline-block">
            <div class="font-size-14 text-left text-white pl-3 font-weight-bold">{{ memberDetails!.FirstName }}</div>
          </div>
        </button>

        <div
          ngbDropdownMenu
          (click)="closeDropDown(ddAccount)"
          aria-labelledby="namedropdown"
          class="shadow text-center text-white border-0 dropdown-menu p-0 account-dropdown mt-2"
        >
          <div class="text-info">
            <a
              *inPopulation="[populations.Default, populations.Partner]"
              [routerLink]="['/account']"
              routerLinkActive="router-link-active"
              attr.aria-label="{{ 'nav.account.screenreader' | translate: { fgbdefault: 'Move to account page' } }}"
              class="
                d-flex
                align-items-center
                account-btn
                text-black
                bg-white
                py-0
                px-2
                font-size-14
                nav-item-fixed-height
                rounded-top
              "
              ngbDropdownItem
            >
              <span class="icon material-icons material-dropdown-icon mr-2" aria-hidden="true">person</span>
              <div class="dropdown-link text-left">
                <div class="mt-2 font-weight-bold">Account</div>
                <div class="nav-bar-little-text text-left text-info" *ngIf="card$ | async as memberCard">
                  {{ memberCard.ProductCode }}
                </div>
              </div>
            </a>
          </div>

          <div class="text-info">
            <a
              *inPopulation="[populations.NonRenewingPopulation]"
              routerLinkActive="router-link-active"
              attr.aria-label="{{ 'nav.account.screenreader' | translate: { fgbdefault: 'Move to account page' } }}"
              class="
                d-flex
                align-items-center
                account-btn
                text-black
                bg-white
                py-0
                px-2
                font-size-14
                nav-item-fixed-height
                rounded-top
              "
              ngbDropdownItem
            >
              <span class="icon material-icons material-dropdown-icon mr-2" aria-hidden="true">person</span>
              <div class="dropdown-link text-left">
                <div class="mt-2 font-weight-bold">Account</div>
                <div class="nav-bar-little-text text-left text-info" *ngIf="card$ | async as memberCard">
                  {{ memberCard.ProductCode }}
                </div>
              </div>
            </a>
          </div>

          <hr class="text-muted w-75 my-0" />

          <ng-container *ngIf="cards$ | async as cards">
            <div *ngIf="cards.length > 1">
              <div class="text-secondary ml-3">
                <div class="d-flex align-items-center account-btn bg-white py-0 px-2 nav-item-fixed-height" ngbDropdownItem>
                  <div class="icon icon-user-male-pictures mr-2 font-size-16 text-primary"></div>
                  <div class="dropdown-link text-left">
                    <div class="font-size-14 text-black font-weight-bold">Switch Account</div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <hr class="text-muted w-75 my-0" />

          <div class="text-white" *inPopulation="[populations.Default]">
            <a
              [routerLink]="['/rewards/wallet']"
              routerLinkActive="router-link-active"
              attr.aria-label="{{ 'nav.wallet.screenreader' | translate: { fgbdefault: 'Move to wallet page' } }}"
              class="d-flex align-items-center account-btn text-black bg-white py-0 px-2 nav-item-fixed-height"
              ngbDropdownItem
            >
              <span class="icon material-icons material-dropdown-icon mr-2" aria-hidden="true">account_balance_wallet</span>
              <div class="dropdown-link text-left">
                <div class="font-size-14 font-weight-bold">{{ 'rewards.nav.wallet' | translate }}</div>
              </div>
            </a>
          </div>
          <hr class="text-muted w-75 my-0" />
          <div *inPopulation="[populations.Default, populations.Partner]">
            <button
              class="logout-btn d-flex align-items-center bg-white px-2 nav-item-fixed-height rounded-bottom"
              (click)="logout()"
              ngbDropdownItem
              attr.aria-label="{{ 'nav.log.out.screenreader' | translate: { fgbdefault: 'Log out' } }}"
            >
              <span class="icon material-icons material-dropdown-icon mr-2 text-danger" aria-hidden="true"
                >power_settings_new</span
              >
              <div class="dropdown-link text-left">
                <div class="text-danger py-1 font-size-14 font-weight-bold" ngbDropdownItem (click)="logout()">
                  {{ 'nav.log.out' | translate: { fgbdefault: 'Log out' } }}
                </div>
              </div>
            </button>
          </div>
          <div *inPopulation="[populations.NonRenewingPopulation]">
            <button
              class="logout-btn d-flex align-items-center bg-white px-2 nav-item-fixed-height rounded-bottom"
              ngbDropdownItem
              attr.aria-label="{{ 'nav.log.out.screenreader' | translate: { fgbdefault: 'Log out' } }}"
            >
              <span class="icon material-icons material-dropdown-icon mr-2 text-danger" aria-hidden="true"
                >power_settings_new</span
              >
              <div class="dropdown-link text-left">
                <div class="text-danger py-1 font-size-14 font-weight-bold" ngbDropdownItem>
                  {{ 'nav.log.out' | translate: { fgbdefault: 'Log out' } }}
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<ng-template #memberDetailsLoading>
  <div class="user-box d-block text-center text-on-primary px-4">
    <fgbcl-loading [size]="'small'"></fgbcl-loading>
  </div>
</ng-template>
