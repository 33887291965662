<ng-container *ngIf="loaded$ | async; else loading">
  <ng-container *ngIf="children$ | async as children">
    <div class="mb-3">
      <div class="card bg-primary text-white">
        <div class="card-body py-2 px-3">
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="kids-text">
                <h3 class="heading-text mt-2 d-flex align-items-center">
                  Children you have added <span class="length-num ml-4">{{ children.data?.length }}/{{ children.limit }}</span>
                </h3>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="d-flex kids-buttons justify-content-lg-end justify-content-center pt-1">
                <a [routerLink]="['/register/newchildren']" *ngIf="children.data?.length < children.limit">
                  <div class="icons-and-buttons">
                    <img src="assets/images/kids/add-kid.svg" class="button-icon" />
                    <div class="text-white font-size-14 font-weight-bold p-0 mt-1 text-uppercase">Add Child</div>
                  </div>
                </a>
                <div *ngIf="children.free?.length > 0">
                  <div class="icons-and-buttons ml-5">
                    <img src="assets/images/kids/upgrade-kid.svg" class="button-icon" (click)="upgradeChildren(children.free)" />
                    <div class="text-white font-size-14 font-weight-bold p-0 mt-1 text-uppercase" (click)="upgradeChildren()">
                      Upgrade Child
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-5">
      <owl-carousel-o [options]="customOptions">
        <ng-template *ngFor="let child of children.data" carouselSlide>
          <div class="mb-1">
            <fgb-child-panel [child]="child"></fgb-child-panel>
          </div>
        </ng-template>
        <ng-template *ngFor="let empty of children.empty" carouselSlide>
          <div class="no-child-border d-flex align-items-center justify-content-center rounded mx-2 mb-1">
            <img class="logo" src="assets/images/logo-small.svg" draggable="false" />
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </ng-container>
</ng-container>

<ng-template #loading>
  <div class="loading-container">
    <fgbcl-loading></fgbcl-loading>
  </div>
</ng-template>
