import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  MarketplaceItem,
  TransactionSuccessService,
  TransactionSuccessQuery,
  ProcessProviderId,
  PurchaseQuery,
  MemberPurchase,
  MemberQuery,
  MarketplaceExtendedQuery,
  AttributeValue,
  PurchaseCustomField,
  MarketplaceService,
} from '@fgb/core';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
@Component({
  selector: 'fgb-marketplace-purchase-success-page',
  templateUrl: './marketplace-purchase-success-page.component.html',
  styleUrls: ['./marketplace-purchase-success-page.component.scss'],
})
export class MarketplacePurchaseSuccessPageComponent implements OnInit, OnDestroy {
  purchasedItem: MarketplaceItem;
  price: number;
  quantity: number;
  theme = '';
  marketplaceItemType = '';
  attributes: AttributeValue[];
  customFields: PurchaseCustomField[];
  purchasedItemLogId: number;
  ProcessProvider = ProcessProviderId;
  private _destroyed$ = new Subject();
  screenType = ScreenType;

  constructor(
    private transactionSuccessQuery: TransactionSuccessQuery,
    private transactionSuccessService: TransactionSuccessService,
    private purchaseQuery: PurchaseQuery,
    private memberQuery: MemberQuery,
    private marketplaceExtendedQuery: MarketplaceExtendedQuery,
    private marketplaceService: MarketplaceService
  ) {}

  ngOnInit() {
    const storeValue = this.transactionSuccessQuery.getValue();
    this.purchasedItem = storeValue.item as MarketplaceItem;
    this.quantity = storeValue.quantity;
    this.price = storeValue.totalPrice;
    this.theme = this.purchasedItem.theme;
    this.marketplaceItemType = this._getMarketplaceItemType(this.purchasedItem.ProcessProviderId);

    combineLatest([this.purchaseQuery.selectPurchases(), this.memberQuery.userContext$])
      .pipe(takeUntil(this._destroyed$))
      .subscribe(([purchases, userContext]) => {
        let orderedPurchases = purchases.filter((p) => p.OrderId === storeValue.orderId);

        let uniquePurchases: MemberPurchase[] = [];
        orderedPurchases.forEach((element) => {
          if (!uniquePurchases.some((p) => p.RewardItemId === element.RewardItemId)) {
            uniquePurchases.push(element);
            this.purchasedItemLogId = element.LogId;
          }
        });

        if (uniquePurchases && uniquePurchases[0].AttributeValues && userContext && userContext.lcid) {
          this.attributes = this.marketplaceExtendedQuery.getLocalisedAttributeValuesFromArray(
            uniquePurchases[0].AttributeValues,
            +userContext.lcid
          );
        }
        if (uniquePurchases && uniquePurchases[0]) {
          this.customFields = uniquePurchases[0].CustomFields;
        }
      });
  }

  /**
   * This will download the purchased marketplace item.
   * @param logId is the log id of the purchased marketplace item.
   */
  downloadPurchasedItem(logId: number) {
    this.marketplaceService
      .downloadMarketplaceItem(logId)
      .toPromise()
      .then((response) => {
        const newBlob = new Blob([response], { type: response.type });
        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }
        const anchor = window.document.createElement('a');
        anchor.href = window.URL.createObjectURL(newBlob);
        // TODO: Return marketplace item download name
        anchor.download = logId.toString();
        document.body.appendChild(anchor);
        anchor.click();
        window.URL.revokeObjectURL(anchor.href);
        document.body.removeChild(anchor);
      });
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
    this.transactionSuccessService.clear();
  }

  /** Gets the marketplace item type text for a given marketplace item process provider ID. */
  private _getMarketplaceItemType(processProviderId: ProcessProviderId): string {
    switch (processProviderId) {
      case ProcessProviderId.Event:
        return 'Event';
      case ProcessProviderId.VirtualEvent:
        return 'Event';
      case ProcessProviderId.Product:
        return 'Product';
      default:
        return 'Marketplace';
    }
  }
}
