<div class="d-none d-lg-block">
  <div class="banner" role="banner">
    <h1 class="text-white" *ngIf="marketplaceItem$ | async">{{ marketplaceItemType.group }}</h1>
  </div>
  <fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>
</div>

<!-- Points Summary -->
<div class="container">
  <fgb-points-summary-bar
    [backButtonLink]="marketplaceItemType.backLink"
    [marketplaceItemGroup]="marketplaceItemType.group"
  ></fgb-points-summary-bar>
</div>

<fgbcl-marketplace-details [itemId]="id" [actionLabels]="actionLabels" [remainingStockOnly]="false"></fgbcl-marketplace-details>

<div class="container mt-3" *ifScreenSize="screenType.Desktop">
  <a class="cursor-pointer d-flex align-items-center" [routerLink]="[marketplaceItemType.backLink]">
    <span class="icon material-icons font-weight-bold pr-2 text-secondary">chevron_left</span>
    <span class="font-size-14 font-weight-bold text-secondary ml-1">Back to {{ marketplaceItemType.group }}</span>
  </a>
</div>
