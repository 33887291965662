import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyOfferModel, CompanyOfferQuery, CompanyOfferService, CompanyService } from '@fgb/core';
import { CompanyQuery } from '@fgb/core';
import { Observable } from 'rxjs';
import { Department } from '@fgb/core';
import { Company } from '@fgb/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { OwlCarouselSettings } from 'src/app/shared/utilities/owl-carousel-settings';
import { map } from 'rxjs/operators';

@Component({
  selector: 'fgb-offer-details-page',
  templateUrl: './offer-details-page.component.html',
  styleUrls: ['./offer-details-page.component.scss'],
})
export class OfferDetailsPageComponent implements OnInit {
  @Input() backButtonLink: string = '/offers';
  @Input() department: Department;
  currentCompany$: Observable<Company | undefined>;
  currentOffer$: Observable<CompanyOfferModel | undefined>;
  relatedOffers$: Observable<CompanyOfferModel[] | undefined>;
  otherOffers$: Observable<CompanyOfferModel[]>;

  customOptions: OwlOptions = {
    autoWidth: true,
    margin: OwlCarouselSettings.Margin,
    pullDrag: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: { items: 1 },
      540: { items: 2 },
      740: { items: 3 },
    },
  };

  constructor(
    private route: ActivatedRoute,
    private companyOfferQuery: CompanyOfferQuery,
    private companyService: CompanyService,
    private companyQuery: CompanyQuery,
    private router: Router,
    private companyOfferService: CompanyOfferService
  ) {}

  ngOnInit() {
    let offerId = +(this.route.snapshot.paramMap.get('offerId') || '0');
    this._updateOffer(offerId);
  }

  loadNewOffer(offerId: number) {
    this.router.navigateByUrl(`offers/details/${offerId}`);
    this._updateOffer(offerId);
  }

  _updateOffer(offerId: number) {
    if (!!offerId) {
      this.companyOfferService.fetchOffers(0, 0).toPromise().then(() =>
        this.companyService.fetchAllCompanies().toPromise()).then(() => {
        let newOffer = this.companyOfferQuery.getEntity(offerId);
        this.currentOffer$ = this.companyOfferQuery.selectEntity(offerId);
        this.relatedOffers$ = this.companyOfferQuery.selectRelatedOffers(offerId);

        if (newOffer) {
          this.currentCompany$ = this.companyQuery.selectEntity(
            newOffer.CompanyId
          );
          this.otherOffers$ = this.companyOfferQuery
            .selectOtherOffers(newOffer.CompanyId)
            .pipe(map((offers) => offers.slice(0, 6)));
        }
      });
    }
  }
}
