<div class="banner" role="banner">
  <h1>Arena Cash</h1>
</div>

<fgb-arena-cash-nav></fgb-arena-cash-nav>

<div class="container">
  <div class="row">
    <div class="col-12">
      <div
        class="transfer-info-box d-flex justify-content-center align-items-center bg-white text-primary text-center font-weight-bold rounded shadow p-2"
      >
        {{ 'transfer.arena.cash.title' | translate }}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="arena-cash-box bg-white rounded shadow">
        <fgb-transfer-arena-cash></fgb-transfer-arena-cash>
      </div>
    </div>
  </div>
</div>
