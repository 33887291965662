<div class="banner" role="banner" *ifScreenSize="screenType.Desktop">
  <h1 class="text-white" *ngIf="memberDetails$ | async as memberDetails">
    {{ memberDetails!.FirstName }} {{ memberDetails!.Surname }}
  </h1>
</div>

<ng-container *ifScreenSize="screenType.Mobile">
  <div class="container my-3">
    <fgb-barcode></fgb-barcode>
  </div>
</ng-container>

<fgb-account-nav></fgb-account-nav>

<div *ngIf="partnerLimit$ | async as partnerLimit" class="container">
  <ng-container *notInPopulation="[populations.Partner]">
    <div class="mb-4 mt-2 mt-lg-0">
      <div class="m-0">
        <h5 class="mt-4 mb-3 title-partner">{{ 'partner.title' | translate }}</h5>

        <p
          class="m-0 font-size-14 partner-description"
          [innerHTML]="'partner.page.info.text' | translate | markdownTranslate"
        ></p>
        <div class="m-0 flex-nowrap pr-0 pl-0">
          <div class="d-flex align-items-center flex-lg-row mb-4 mt-4">
            <button
              *ifScreenSize="screenType.Desktop"
              class="
                partner-button
                border-0
                text-center text-white
                bg-primary
                pt-0
                d-flex
                align-items-center
                justify-content-center
                mr-lg-4
                mb-3 mb-lg-0
              "
              (click)="openAddForm()"
              aria-label="Add new partner"
              [ngClass]="{
                active: !showAcceptForm,
                'not-active': showAcceptForm,
                'greyed-out': currentPartnerCount >= partnerLimit
              }"
            >
              <span class="material-icons mr-2"> person_add_alt_1 </span>
              <label class="cursor-pointer mb-0">Add New Partner</label>
            </button>
            <button
              *ifScreenSize="screenType.Mobile"
              class="
                mb-partner-button
                border-0
                text-center text-white
                bg-primary
                pt-0
                d-flex
                align-items-center
                justify-content-center
                mr-lg-4
                mb-3 mb-lg-0
              "
              (click)="openAddForm()"
              aria-label="Add new partner"
              [ngClass]="{
                active: !showAcceptForm,
                'not-active': showAcceptForm,
                'greyed-out': currentPartnerCount >= partnerLimit
              }"
            >
              <span class="material-icons mr-2"> person_add_alt_1 </span>
              <label class="cursor-pointer mb-0">{{ 'nav.add.partner' | translate: { fgbdefault: 'Add New Partner' } }}</label>
            </button>
          </div>
        </div>
      </div>
      <ng-container *ngIf="showAddForm || showAcceptForm">
        <hr class="m-2" />
        <div class="card-body">
          <fgb-partner-add-form (close)="openAddForm()" *ngIf="showAddForm"></fgb-partner-add-form>
          <fgb-partner-accept-form (close)="openAcceptForm()" *ngIf="showAcceptForm"></fgb-partner-accept-form>
        </div>
      </ng-container>
    </div>
    <hr class="partner-divider w-100" />
  </ng-container>
  <ng-container *ngIf="currentPartnerCount > 0; else partnerPlaceholder">
    <fgb-partner-list></fgb-partner-list>
  </ng-container>
  <ng-template #partnerPlaceholder>
    <h5 class="mt-4 mb-3 title-partner" *inPopulation="[populations.Default]">
      {{ 'no.partner.placeholder.title' | translate }}
    </h5>
    <h5 class="mt-4 mb-3 title-partner" *inPopulation="[populations.Partner]">{{ 'partner.list.partner.title' | translate }}</h5>
    <div class="card border card-primary my-2 bg-white font-size-14">
      <div class="card-body pt-2">
        <div class="row text-left details-header">
          <div class="col-10 col-lg-3 d-lg-none d-block">
            <label class="font-weight-bold">{{ 'partner.details' | translate }}</label>
          </div>
          <div class="col-3 d-lg-block d-none">
            <label class="font-weight-bold">{{ 'partner.fullname' | translate }}</label>
          </div>
          <div class="col-5 d-lg-block d-none">
            <label class="font-weight-bold">{{ 'partner.email' | translate }}</label>
          </div>
          <div class="col-3 d-lg-block d-none"></div>
        </div>
        <hr class="partner-divider w-100" />
        <div class="text-info text-center font-size-12">{{ 'no.partner.placeholder.description' | translate }}</div>
      </div>
    </div>
  </ng-template>
</div>
