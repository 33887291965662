<div class="survey-answer row my-3">
    <div class="col-md-6 order-md-0 order-12">
      <div
        class="survey-answer-container"
        [ngClass]="hasSelectedCorrect ? 'justify-content-lg-end justify-content-center mr-0 mr-lg-4' : 'justify-content-around'"
      >
        <div
          class="survey-answer-item m-2"
          [ngClass]="hasSelectedCorrect ? 'correct' : 'incorrect'"
          *ngFor="let answer of selectedAnswers"
        >
          <div class="image-container image-border">
            <img src="{{ answer.ImageURL | contentImage: '7' }}" alt="{{ answer.ImageURL }}" />
          </div>
        </div>
        <div class="survey-answer-item correct m-2" *ngIf="!hasSelectedCorrect && correctAnswer">
          <div class="image-container image-border">
            <img src="{{ correctAnswer.ImageURL | contentImage: '7' }}" alt="{{ correctAnswer.ImageURL }}" />
          </div>
        </div>
      </div>
    </div>
    <div class="survey-answer-text-container col-md-6 order-md-12 order-0">
      <ng-container *ngIf="hasSelectedCorrect; else incorrect">
        <div class="survey-message text-success">
          <div>
            <ng-container *ngIf="pointsWon; else noPoints">
              <div class="text-center font-weight-bold font-size-14">Correct Answer !</div>
              <div class="text-center points-won font-weight-bold">+{{ pointsWon }} Points</div>
            </ng-container>
            <ng-template #noPoints>
              <div class="font-weight-bold font-size-14">Correct Answer !</div>
            </ng-template>
          </div>
        </div>
      </ng-container>
      <ng-template #incorrect>
        <div class="survey-message text-danger">
          <div>
            <div class="text-center font-weight-bold font-size-14">Wrong Answer !</div>
            <div class="text-center points-won font-weight-bold">Better luck next time</div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  