import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { TransactionExtended, TransactionExtendedQuery, TransactionExtendedService } from '@fgb/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fgb-split-transaction-list',
  templateUrl: './split-transaction-list.component.html',
  styleUrls: ['./split-transaction-list.component.scss'],
  exportAs: 'splitTransaction'
})
export class SplitTransactionListComponent implements OnInit, OnDestroy {
  
  loading$: Observable<boolean>;
  transactions$: Observable<{ date: string, transactions: TransactionExtended[] }[]>;
  pageNo: number = 1;
  isLoadDisabled: boolean;
  noSearchEnabled:boolean=false;
  _destroyed$ = new Subject();
  @Input() pageSize: number = 10;
  @Input() dateFrom: Date;
  @Input() dateTo: Date;
  @Input() resetOnLoad: boolean = true;

  constructor(
              private transactionService: TransactionExtendedService,
              private transactionExtendedQuery: TransactionExtendedQuery) { }

  ngOnInit(): void {

    // When navigating to other pages some unexpected issue occurs, this resets everything to default
    if( this.resetOnLoad ){
      this.reset();
    }

    this.transactions$ = this.transactionExtendedQuery.groupedByDate$;
    this.loading$ = this.transactionExtendedQuery.loading$;

  }

  ngOnDestroy(): void{
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  trackByFn(index: number, item: TransactionExtended){
    return item.TransactionRef;
  }

  _search(from: Date, to: Date, pageNo?: number, pageSize?: number): void{
    this.pageNo = pageNo || this.pageNo;
    this.pageSize = pageSize || this.pageSize;
    this.transactionService.search(1, from, to, this.pageNo, this.pageSize)
    .pipe(
      takeUntil(this._destroyed$)
    )
    .subscribe(res => {
        // Disable load more button if nothing is returned or result is less than the pageSize
        this.isLoadDisabled = !res || res?.length < this.pageSize;
    });
  }

  search(): void{
    this._search(this.dateFrom, this.dateTo);
    this.noSearchEnabled = true;
  }

  searchByDate(dateFrom: Date, dateTo: Date): void{
    this.dateFrom = dateFrom || this.dateFrom;
    this.dateTo = dateTo || this.dateTo;
    this._search(dateFrom, dateTo);
  }

  loadMore(): void{
    this._search(this.dateFrom, this.dateTo, ++this.pageNo);
  }

  reset(): void{
    this.pageNo = 1;
    this.isLoadDisabled = false;
    this.transactionService.reset();
  }

}
